<div class="margin-bottom">
    <div class="header text-right no-pm-0 mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Mantenimiento de alertas</h3>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="createAlertForm">
                        <div class="row">
                            <div class="col-6">
                                <label for="id">Alerta</label>
                                <div>
                                    <select class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                        id="alertName" (change)="AlertSelected($event)"
                                        [ngClass]="{ 'is-invalid': AlertForm.alertName.errors && (AlertForm.alertName.touched || AlertForm.alertName.untouched) }">
                                        <option value="">Seleccione una alerta</option>
                                        <option *ngFor="let alert of alertList" [value]="alert.Id">
                                            {{alert.AlertName}}
                                        </option>
                                    </select>
                                    <!-- error block -->
                                    <div class="invalid-feedback" *ngIf="AlertForm.alertName.errors">
                                        <sup>*</sup>Por favor seleccione una alerta
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <label for="id">Días para el plan</label>
                                <div class="row">
                                    <div class="col-2" *ngFor="let day of weekDays">
                                        <input type="checkbox" (change)="onChange(day.id, $event.target.checked)"
                                            [checked]="day.checked" />
                                        {{ day.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="id">Asunto del correo</label> <br />
                                <textarea class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                    name="emailSubject" formControlName="emailSubject" id="emailSubject"
                                    [ngClass]="{ 'is-invalid': AlertForm.emailSubject.errors && (AlertForm.emailSubject.touched || AlertForm.emailSubject.untouched) }">
                        </textarea>
                                <!-- error block -->
                                <div class="invalid-feedback" *ngIf="AlertForm.emailSubject.errors">
                                    <sup>*</sup>Por favor digite el asunto del correo
                                </div>
                            </div>
                            <div class="col-md-12">
                                <label for="id">Cuerpo del correo</label> <br />
                                <textarea class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                    name="emailBody" formControlName="emailBody" id="emailBody"
                                    [ngClass]="{ 'is-invalid': AlertForm.emailBody.errors && (AlertForm.emailBody.touched || AlertForm.emailBody.untouched) }">
                        </textarea>
                                <!-- error block -->
                                <div class="invalid-feedback" *ngIf="AlertForm.emailBody.errors">
                                    <sup>*</sup>Por favor digite el cuerpo del correo
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-md-3">
                                <button class="form-control" (click)="updateAlert()"
                                    [disabled]="!createAlertForm.valid">
                                    <i class="fa fa-refresh"></i>&nbsp;Actualizar alerta</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
