import { Injectable } from '@angular/core';
// Angular imports
import { Subject } from '../common/angular.index';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {

  toggler: Subject<boolean>;
  currentPath: Subject<string>;
  constructor() { 
    this.toggler = new Subject();
    this.currentPath = new Subject();
  }
}
