<div class="margin-bottom">
    <div class="header text-right mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Mantenimiento de correos para alertas</h3>
        </div>
    </div>
</div>
<div class="mt-2">
    <ngb-tabset class="pt-2" [destroyOnHide]="false">
        <ngb-tab title="Correos">
            <ng-template ngbTabContent>
                <div class="row justify-content-md-center pt-3">
                    <div class="col-sm-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="emailForm">
                                    <div class="row row-space">
                                        <div class="col-md-4">
                                            <label for="id">Correos disponibles</label>
                                            <div>
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="userSelected" (change)="userSelected($event)"
                                                    >
                                                    <option value=""></option>
                                                    <option *ngFor="let manager of EmailConfigList" [value]="manager.Id">
                                                        {{manager.Email}}
                                                    </option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="emailFormManager.userSelected.errors">
                                                    <sup>*</sup>Por favor seleccione un correo
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="id">Correo</label>
                                            <input type="text" name="managerEmail"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="managerEmail" formControlName="managerEmail"
                                                [ngClass]="{ 'is-invalid': emailFormManager.managerEmail.errors && (emailFormManager.managerEmail.touched || emailFormManager.managerEmail.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="emailFormManager.managerEmail.errors">
                                                <sup>*</sup>Verificar correo
                                            </div>
                                        </div> 
                                        <div class="col-md-4">
                                            <label for="id">Contraseña</label>
                                            <div>
                                                <input type="password" name="managerPassword"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPassword" formControlName="managerPassword"
                                                    >
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="emailFormManager.managerPassword.errors">
                                                    <sup>*</sup>Por favor digite la contraseña
                                                </div>
                                            </div>
                                        </div>                                       
                                        
                                    </div>
                                    <div class="row row-space pt-2">                                        
                                        <div class="col-md-3">
                                            <label for="id">Estado</label>
                                            <select
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                formControlName="managerStatus"
                                                [ngClass]="{ 'is-invalid': emailFormManager.managerStatus.errors && (emailFormManager.managerStatus.touched) }">
                                                <option value="">Seleccione un estado</option>
                                                <option value="true">Activo</option>
                                                <option value="false">Inactivo</option>
                                            </select>
                                            <!-- error block -->
                                            <div class="invalid-feedback"
                                                *ngIf="emailFormManager.managerStatus.errors">
                                                <sup>*</sup>Por favor seleccione un estado
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Host</label>
                                            <div>
                                                <input type="text" name="managerHost"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerHost" formControlName="managerHost"
                                                    [ngClass]="{ 'is-invalid': emailFormManager.managerHost.errors && (emailFormManager.managerHost.touched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="emailFormManager.managerHost.errors">
                                                    <sup>*</sup>Por favor digite un host
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Puerto</label>
                                            <div>
                                                <input type="text" name="managerPort"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPort" formControlName="managerPort"
                                                    [ngClass]="{ 'is-invalid': emailFormManager.managerPort.errors && (emailFormManager.managerPort.touched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="emailFormManager.managerPort.errors">
                                                    <sup>*</sup>Por favor digite un puerto
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id" class="mb-0">SSL</label>
                                            <div class="mt-0">
                                                <label class="switch">
                                                    <input type="checkbox" formControlName="managerSSL">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-2 pt-2">
                                            <button class="form-control" (click)="checkAction()"
                                                [disabled]="!emailForm.valid">
                                                <i class="fa fa-check"></i>&nbsp;Aceptar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </ng-template>
        </ngb-tab>        
    </ngb-tabset>
</div>