import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SidebarModule } from 'ng-sidebar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BlockUIModule } from 'ng-block-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { CreditDaysPipe } from './pipes/credit-days.pipe';
import { ReportsComponent } from './components/reports/reports.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { HttpErrorInterceptor } from './services/error-interceptor';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { BinnacleComponent } from './components/binnacle/binnacle.component';
import { UploadsComponent } from './components/uploads/uploads.component';
import { UsersComponent } from './components/users/users.component';
import { UsersAssignsComponent } from './components/users-assigns/users-assigns.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ProgressComponent } from './components/progress/progress.component';
import { DndDirective } from './directives/dnd.directive';
import { LoginComponent } from './components/login/login.component';
import { AlertComponent } from './components/interest/configuration/alert/alert.component';
import { AssignAlertToCompanyComponent } from './components/interest/configuration/assing/assing.component';
import { CompanyComponent } from './components/interest/configuration/company/company.component';
import { ManagerComponent } from './components/interest/configuration/manager/manager.component';
import { ManagerByCompanyComponent } from './components/interest/configuration/manager-by-company/manager-by-company.component';
import { BplogsComponent } from './components/interest/search/bplog/bplog.component';
import { CompanylogsComponent } from './components/interest/search/companylog/companylog.component';
import { SupplierlogsComponent } from './components/interest/search/supplierlog/supplierlog.component';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { AlertEmailComponent } from './components/interest/configuration/alert-email/alert-email.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    CreditDaysPipe,
    ReportsComponent,
    PrintReportComponent,
    FileManagerComponent,
    BinnacleComponent,
    UploadsComponent,
    UsersComponent,
    UsersAssignsComponent,
    SettingsComponent,
    ProgressComponent,
    DndDirective,
    LoginComponent,
    AlertComponent,
    AssignAlertToCompanyComponent,
    CompanyComponent,
    ManagerComponent,
    ManagerByCompanyComponent,
    BplogsComponent,
    CompanylogsComponent,
    SupplierlogsComponent,
    AlertEmailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SidebarModule.forRoot(),
    NgbModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
