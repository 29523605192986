import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, RoleService, AlertService, PermissionService, StorageService } from 'src/app/services';
import { IUser, IPermission, AppConstants, IRole } from 'src/app/models';
import { ALERTS } from 'src/app/common/enums';
import { NgbModalRef, NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIHandlerService } from 'src/app/common/services.index';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CDK_DROP_LIST_GROUP } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-users-assigns',
  templateUrl: './users-assigns.component.html',
  styleUrls: ['./users-assigns.component.css']
})
export class UsersAssignsComponent implements OnInit {

  //VARBOX
  assignedRoles: IRole[];
  roles: IRole[];
  role: IRole;
  isPermissionNavActive: boolean;
  isRoleNavActive: boolean;
  roleForm: FormGroup;
  permissions: IPermission[];
  Assignedpermissions: IPermission[];
  permissionForm: FormGroup;
  modalReference: NgbModalRef;
  active = 'top';
  userControl: FormControl;
  users: IUser[];
  permission: IPermission;

  constructor(
    private userService: UserService
    , private roleService: RoleService
    , private alertService: AlertService
    , private formBuilder: FormBuilder
    , private modalService: NgbModal
    , private permissionService: PermissionService
    , private blockUiService: BlockUIHandlerService
    , private storageService: StorageService) { }

  ngOnInit(): void {
    this.InitVariables();
    this.LoadData();
  }

  InitVariables(): void {
    this.isPermissionNavActive = false;
    this.isRoleNavActive = false;
    this.users = [];
    this.Assignedpermissions = [];
    this.permissions = [];
    this.assignedRoles = [];
    this.roles = [];
    this.ResetUserControl();
    this.ResetPermissionForm();
    this.ResetRoleForm();
  }

  LoadData(): void {
    this.GetUsers();
    this.GetPermissions();
    this.GetRoles();
  }

  GetUsers(): void {
    this.blockUiService.Start(1);
    this.userService.Get().subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.users = next.Users;
        this.users = next.Users.filter(x => x.IsActive);
        this.users.forEach(x => x.IsSelected = false);
        if (this.users.length > 0) {
          this.users[0].IsSelected = true;
          this.SelectUser(this.users[0]);
        }
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.warning);
    });
  }

  GetPermissions(_filtered = false, _onAssignation = false): void {
    this.blockUiService.Start(1);
    this.permissionService.Get().subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.permissions = next.Permissions;
        if (_filtered) this.permissions = next.Permissions.filter(x => x.IsActive);

        if (_onAssignation) {
          this.Assignedpermissions.forEach(x => {
            this.permissions = this.permissions.filter(y => y.Id != x.Id);
          });
        }
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.warning);
    });
  }

  ResetUserControl(): void {
    this.userControl = new FormControl(-1);
  }

  OnUserControlChange(): void {

  }

  UpdateRoles(): void {
    if (this.userControl.value == -1) {

      this.alertService.ShowSmallCornerAlert(`Seleccione un usuario`, ALERTS.info);
      return;
    }
  }

  RaisePermissionModal(content, isUpdating: boolean = false, _isOnRole = false): void {
    if (_isOnRole) {
      if (!isUpdating) this.ResetRoleForm();
    }
    else {
      if (!isUpdating) this.ResetPermissionForm();
    }
    this.modalReference = this.modalService.open(content,
      {
        centered: true,
        backdrop: 'static'
      });
  }

  ResetPermissionForm(): void {
    this.permissionForm = this.formBuilder.group({
      Id: [-1],
      Name: ['', Validators.required],
      Code: ['', Validators.required],
      Type: ['', Validators.required],
      IsActive: [true, Validators.required]
    });
  }

  ResetRoleForm(): void {
    this.roleForm = this.formBuilder.group({
      Id: [-1],
      Name: ['', Validators.required],
      IsActive: [true, Validators.required]
    });
  }

  ClosePermissionModal(): void {
    this.modalReference.close();
  }

  Store(): void {

    if (this.isPermissionNavActive) {
      const PERMISSION = this.permissionForm.value as IPermission;
      if (!this.permissionForm.valid) {
        this.alertService.ShowSmallCornerAlert(`Todos los campos son requeridos`, ALERTS.info);
        return;
      }

      PERMISSION.Id === -1 ? this.CreatePermission(PERMISSION) : this.UpdatePermission(PERMISSION);
    }
    else {
      const ROLE = this.roleForm.value as IRole;

      if (!this.roleForm.valid) {
        this.alertService.ShowSmallCornerAlert(`Todos los campos son requeridos`, ALERTS.info);
        return;
      }

      ROLE.Id === -1 ? this.CreateRole(ROLE) : this.UpdateRole(ROLE);
    }

  }

  GetPermission(_id: number, content): void {
    this.permissionService.GetPermission(_id).subscribe(next => {
      if (next.Result) {
        this.permission = next.Permission;
        this.permissionForm.patchValue({
          ...this.permission
        });
        this.RaisePermissionModal(content, true, true);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  GetPermissionType(_type: number): string {
    return AppConstants.GetPermissionType(_type);
  }

  CreatePermission(_permission: IPermission): void {
    this.blockUiService.Start(1, `Agregando permiso, espere por favor`);
    this.permissionService.StorePermission(_permission).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(`Permiso agregado`, ALERTS.success);
        this.ClosePermissionModal();
        this.GetPermissions();
      }
      else {
        console.log(next.ErrorInfo);
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  UpdatePermission(_permission: IPermission): void {
    this.blockUiService.Start(1, `Actualizando permiso, espere por favor`);
    this.permissionService.Put(_permission).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(`Permiso actualizado`, ALERTS.success);
        this.ClosePermissionModal();
        this.GetPermissions();
      }
      else {
        console.log(next.ErrorInfo);
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    switch (changeEvent.nextId) {
      case 1:
        this.GetUsers();
        this.GetRoles(true, true);
        this.isPermissionNavActive = false;
        this.isRoleNavActive = false;
        break;
      case 2:
        this.isRoleNavActive = true;
        this.isPermissionNavActive = false;
        this.GetRoles();
        this.GetPermissions(true, true);

        break;
      case 3:
        this.isRoleNavActive = false;
        this.isPermissionNavActive = true;
        this.GetPermissions();
        break;

    }
  }

  drop(event: CdkDragDrop<string[]>, _isOnRoleTab = true) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      console.log('Transfering item to new container')
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      if (_isOnRoleTab) {
        this.permissionService.PutByRole(this.roles.find(x => x.IsSelected).Id, this.Assignedpermissions).subscribe(next => {
          if (next.Result) {
            const PLURALIZED = this.Assignedpermissions.length > 0 ? 's' : '';
            this.alertService.ShowSmallCornerAlert(`Role${PLURALIZED} asignado${PLURALIZED}`, ALERTS.success);
            this.OverridePermissions();
          }
          else {
            console.log(next);
            this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
          }
        }, error => {
          console.log(error);
          this.blockUiService.Stop();
          this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
        });
      }
      else {
        this.userService.PutByRole(this.users.find(x => x.IsSelected).Id, this.assignedRoles).subscribe(next => {
          if (next.Result) {
            const PLURALIZED = this.assignedRoles.length > 0 ? 's' : '';
            this.alertService.ShowSmallCornerAlert(`Role${PLURALIZED} asignado${PLURALIZED}`, ALERTS.success);
            this.OverridePermissions();
          }
          else {
            console.log(next);
            this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
          }
        }, error => {
          console.log(error);
          this.blockUiService.Stop();
          this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
        });
      }
    }
  }

  OffsetPermissions(_option: number): void {
    if (_option === 0) {
      this.Assignedpermissions.forEach(x => this.permissions.push({ ...x }));
      this.Assignedpermissions = [];
    }
    else {
      this.Assignedpermissions.concat(this.permissions);
      this.permissions.forEach(x => this.Assignedpermissions.push({ ...x }));
      this.permissions = [];
    }

    this.permissionService.PutByRole(this.roles.find(x => x.IsSelected).Id, this.Assignedpermissions).subscribe(next => {
      console.log(next);
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(`Permisos asignados`, ALERTS.success);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  OffsetRoles(_option: number): void {
    if (_option === 0) {
      this.assignedRoles.forEach(x => this.roles.push({ ...x }));
      this.assignedRoles = [];
    }
    else {
      this.assignedRoles.concat(this.roles);
      this.roles.forEach(x => this.assignedRoles.push({ ...x }));
      this.roles = [];
    }

    this.userService.PutByRole(this.users.find(x => x.IsSelected).Id, this.assignedRoles).subscribe(next => {
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(`Roles asignados`, ALERTS.success);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  GetRoles(_filtered = false, _onAssignation = false): void {
    this.blockUiService.Start(1);
    this.roleService.Get().subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.roles = next.Roles;
        if (this.roles.length > 0) {
          this.roles.forEach(x => x.IsSelected = false);
          this.roles[0].IsSelected = true;
        }

        if (_filtered) this.roles = next.Roles.filter(x => x.IsActive);

        if (_onAssignation) {
          this.assignedRoles.forEach(x => {
            this.roles = this.roles.filter(y => y.Id != x.Id);
          });
        }
        if (this.roles.length > 0) this.SelectRole(this.roles[0]);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  GetRole(_id: number, content: unknown): void {
    this.roleService.GetRole(_id).subscribe(next => {
      if (next.Result) {
        this.roleForm.patchValue({ ...next.Role });
        this.RaisePermissionModal(content, true, true);
      }
      else {
        console.log(next);
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  UpdateRole(_role: IRole): void {
    this.blockUiService.Start(1, `actualizando rol, espere por favor`);
    this.roleService.Put(_role).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.roles.find(x => x.Id === _role.Id).IsActive = _role.IsActive;
        this.alertService.ShowSmallCornerAlert(`Rol actualizado`, ALERTS.success);
        this.ClosePermissionModal();

        this.OverridePermissions();
      }
      else {
        console.log(next.ErrorInfo);
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  CreateRole(_role: IRole): void {
    this.blockUiService.Start(1, `Agregando rol, espere por favor`);
    this.roleService.Post(_role).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(`Rol agregado`, ALERTS.success);
        this.ClosePermissionModal();
        this.GetRoles();
      }
      else {
        console.log(next.ErrorInfo);
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }


  SelectRole(_role: IRole) {
    this.roles.forEach(x => x.IsSelected = false);
    this.roles.find(x => x.Id === _role.Id).IsSelected = true;

    this.permissionService.GetPermissionsByRole(_role.Id).subscribe(next => {
      if (next.Result) {
        this.Assignedpermissions = next.Permissions;
        this.GetPermissions(true, true);
      }
      else {
        console.log(next.Permissions);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
      }

    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  SelectUser(_user: IUser): void {
    this.users.forEach(x => x.IsSelected = false);
    this.users.find(x => x.Id === _user.Id).IsSelected = true;

    this.roleService.GetByUser(_user.Id).subscribe(next => {
      if (next.Result) {
        this.assignedRoles = next.Roles;
        this.GetRoles(true, true);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
      }

    }, error => {
      console.log(error);
      this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  private OverridePermissions(): void {
    const USER = JSON.parse(this.storageService.GetCurrentSession());
    if (USER) {
      this.permissionService.GetPermissionsByUser(+USER.Id).subscribe(next => {
        if (next.Result && next.Permissions && next.Permissions.length > 0) {
          this.storageService.SetPermissions(next.Permissions);
        }
        else {
          console.log(next);
          this.alertService.ShowSmallCornerAlert(`No se pudieron recargar los permisos`, ALERTS.warning);
        }
      }, error => {
        this.alertService.ShowSmallCornerAlert(`No se pudieron recargar los permisos`, ALERTS.warning);
      });
    }
    else {
      this.alertService.ShowSmallCornerAlert(`No se pudieron recargar los permisos`, ALERTS.warning);
    }
  }
}
