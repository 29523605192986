import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, Observable } from 'src/app/common/angular.index';
import { AppConstants } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class CommonServices {

  constructor(private httpClient: HttpClient) { }


}
