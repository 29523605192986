<div class="container-fluid">
  <!-- <div class="row justify-content-center mt-5">
    <div class="col-6 text-center">
      <img src="../../../assets/images/GAP.png" alt="ICEM" class="img-fluid" />
    </div>
  </div> -->
  <div class="row-10 justify-content-center">
    <div class="col centerLogo">
      <div>
        <img class="centerImg" src="assets/images/Logo Polycenter.png">
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-6">
      <div class="row justify-content-center">
        <div
          class="col-12 col-sm-3 quick-access"
          *ngFor="let access of accessList; index as i">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 border rounded">
                <div class="row">
                  <button
                    class="btn btn-sm"
                    style="box-shadow: none"
                    (click)="onClickDeleteAccess(i)"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="row">
                  <div class="col-12 text-center">
                    <img
                      [src]="
                        'http://www.google.com/s2/favicons?domain=' +
                        access.Domain
                      "
                      alt="access.Name"
                    />
                  </div>
                  <div class="col-12">
                    <a [href]="access.URL">
                      <p class="text-center border-top my-2">
                        {{ access.Name }}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-sm-3 rounded quick-access center">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <button
                  class="btn rounded-circle bg-light"
                  title="Crear nuevo acceso directo"
                  (click)="onClickQuickAccessModal(modalQuickAccess)"
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #modalQuickAccess let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Nuevo acceso directo</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="dismissModal(false)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <form class="row" [formGroup]="quickAccessForm">
        <div class="col-12">
          <div class="form-group row">
            <label class="col-4 col-form-label">Nombre</label>
            <div class="col-8">
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="Name"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group row">
            <label class="col-4 col-form-label">Dirección</label>
            <div class="col-8">
              <input
                type="text"
                class="form-control form-control-sm"
                formControlName="URL"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <div class="container-fluid">
      <div class="row justify-content-end">
        <button
          class="btn btn-sm btn-success mx-1"
          (click)="onClickSaveChanges()"
          [disabled]="quickAccessForm.invalid"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</ng-template>
