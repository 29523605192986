<ng-sidebar-container>
    <ng-sidebar [(opened)]="opened" [mode]="sidebarMode" (openedChange)="openedChange($event)">
        <div class="container-fluid" *ngIf="currentUser && opened">
            <div class="row">
                <div class="col-12 mb-5" id="sidebarTitle">
                    <div class="text-center mt-2">
                        <h4>
                            {{ sessionService.session.Company }}
                        </h4>
                        <p class="border-bottom">
                            {{ currentUser?.UserName }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-12 my-1" *ngFor="let option of menu; index as i" (click)="onClickMenuOption(option)">
                <div class="row">
                    <div class="col-12 menu-option" data-toggle="collapse" [attr.data-target]="'#subMenu-' + i" [ngClass]="{ 'menu-option-selected': option.Selected }">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-2">
                                        <i class="fa-lg mr-4" [ngClass]="[option.Icon]"></i>
                                    </div>
                                    <div class="col-10">{{ option.Name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 collapse" *ngIf="option.Children?.length > 0" [attr.id]="'subMenu-' + i">
                        <div class="row menu-option" *ngFor="let subMenu of option.Children; let j = index" (click)="onClickMenuOption(subMenu)">
                            <div class="col-10 offset-2" data-toggle="collapse" [attr.data-target]="'#child-' + j">
                                {{ subMenu.Name }}
                            </div>


                            <div class="col-12 collapse" *ngIf="subMenu.Children?.length > 0" [attr.id]="'child-' + j">
                                <div class="row menu-option" *ngFor="let child of subMenu.Children" (click)="onClickMenuOption(child)">
                                    <div class="col-10 offset-3 ssm-zoom">
                                        {{ child.Name }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-sidebar>

    <div ng-sidebar-content>
        <div class="container-fluid p-0">
            <app-header></app-header>
            <div id="router-outlet-container" class="pb-1 pt-3 outlet-container-padding">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-sidebar-container>