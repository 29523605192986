import { Injectable } from '@angular/core';
import { Router, filter } from '../common/angular.index';
import { NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  currentSection = 'Inicio';

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.defineTitleBasedOnURL(event.url);
      });
  }

  defineTitleBasedOnURL(URL: string) {
    let splitURL = URL.split('/');

    switch (splitURL[1]) {
      case '':
      case 'home':
        this.currentSection = 'Inicio';
        break;
      case 'binnacle':
        this.currentSection = 'Bitácora';
        break;
      case 'file-manager':
        this.currentSection = 'Archivos';
        break;
      case 'document-states':
        this.currentSection = 'Estados';
        break;
      case 'requirements':
        this.currentSection = 'Requisitos';
        break;
      case 'contacts':
        this.currentSection = 'Contactos';
        break;
      case 'users':
        this.currentSection = 'Usuarios';
        break;
      case 'user-assigns':
        this.currentSection = 'Asignaciones';
        break;
      case 'print-report':
        this.currentSection = 'Imprimir reporte';
        break;
      case 'reports-crud':
        this.currentSection = 'Administrar reportes';
        break;
      default:
        break;
    }
  }
}
