import { Component, OnInit, ViewChild } from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// Angular
import {
  FormBuilder,
  FormGroup,
  NgbModal,
  NgbNav,
  Router,
  Validators,
} from 'src/app/common/angular.index';
// Models
import {
  ALERTS,
  ReportParameter2,
  Report,
  Report2,
  REPORT_PARAMETER,
  ParameterOption,
  ReportUser,
} from 'src/app/common/models.index';
// Services
import {
  AlertService,
  BlockUIHandlerService,
  CommonService,
  RptManagerService,
} from 'src/app/common/services.index';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
  @ViewChild('nav', { static: true }) nav: NgbNav;

  activeTab: number;
  reportForm: FormGroup;
  parameterForm: FormGroup;
  parameterValueForm: FormGroup;

  reportUsers: ReportUser[];
  parameters: ReportParameter2[];
  reports: Report[];
  report: File;
  parameterOptions: ParameterOption[];
  parameterOptionsModal: ParameterOption[];

  parameterSubgroup1: ReportParameter2[];
  parameterSubgroup2: ReportParameter2[];
  parameterSubgroup3: ReportParameter2[];
  parameterSubgroup4: ReportParameter2[];
  paramaterListGroup: ReportParameter2[][];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService,
    private commonService: CommonService,
    private rptManagerService: RptManagerService,
    private blockUI: BlockUIHandlerService
  ) {}

  ngOnInit(): void {
    this.nav.activeIdChange.subscribe(() => this.onTabChange());
    this.reportForm = this.formBuilder.group({
      Id: 0,
      Name: ['', Validators.required],
      DisplayName: ['', Validators.required],
      ReportUserId: [0, Validators.required],
    });

    this.parameterForm = this.formBuilder.group({
      Id: [0],
      ReportId: [0],
      Name: ['', Validators.required],
      Type: [1, Validators.required],
      Required: ['S', Validators.required],
    });

    this.parameterValueForm = this.formBuilder.group({
      Key: ['', Validators.required],
      Value: ['', Validators.required],
    });
    this.initializeApp();

    this.blockUI.Start(2);
    this.getReportUsers();
    this.getReports();
  }

  initializeApp() {
    this.parameterSubgroup1 = [];
    this.parameterSubgroup2 = [];
    this.parameterSubgroup3 = [];
    this.parameterSubgroup4 = [];
    this.paramaterListGroup = [];
    this.parameters = [];
    this.paramaterListGroup.push(
      this.parameterSubgroup1,
      this.parameterSubgroup2,
      this.parameterSubgroup3,
      this.parameterSubgroup4
    );
    this.parameterOptions = [];
    this.parameterOptionsModal = [];
    this.activeTab = 1;
    this.report = null;
    this.resetParameterForm();
    this.resetReportForm();
    this.resetParameterValueForm();
  }

  resetParameterForm() {
    this.parameterForm.reset({
      Id: 0,
      ReportId: 0,
      Name: '',
      Type: 1,
      Required: 'S'
    });
  }

  resetReportForm() {
    this.reportForm.reset({
      Id: 0,
      Name: '',
      DisplayName: '',
      ConnectionType: 1,
    });
  }

  resetParameterValueForm() {
    this.parameterValueForm.reset({
      Key: '',
      Value: '',
    });
  }

  getReportUsers() {
    this.reportUsers = [];
    this.rptManagerService
      .GetReportUsers()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.taskFinished();
          if (response.Result) this.reportUsers = response.ReportUsers;
          else
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
        },
        (err) => {
          this.blockUI.taskFinished();
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  getReports() {
    this.reports = [];
    this.rptManagerService
      .GetReports()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.taskFinished();
          if (response.Result) {
            this.reports = response.Reports;
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          this.blockUI.taskFinished();
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  HandlePostOrPutReport(report: Report2) {
    this.rptManagerService
      .HandlePostOrPutReport(report)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.blockUI.taskFinished();

          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(
              'Procose finalizado exitosamente',
              ALERTS.success
            );

            this.initializeApp();
            this.getReports();
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          this.blockUI.Stop();
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  onClickEditReport(report: Report) {
    this.reportForm.reset({
      Id: report.Id,
      Name: report.Name,
      DisplayName: report.DisplayName,
      ReportUserId: report.ReportUserId,
    });

    this.rptManagerService
      .GetParameters(report.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) {
            this.activeTab = 2;
            if (response.Parameters && response.Parameters.length > 0) {
              response.Parameters.forEach((x) => {
                switch (x.GridCol) {
                  case 0:
                    this.parameterSubgroup1.push(x);
                    break;
                  case 1:
                    this.parameterSubgroup2.push(x);
                    break;
                  case 2:
                    this.parameterSubgroup3.push(x);
                    break;
                  case 3:
                    this.parameterSubgroup4.push(x);
                    break;
                }
              });
            }
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          console.log(err);
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  async onClickSaveChanges() {
    const report = this.getReportModel();

    if (!report.Parameters || report.Parameters.length === 0) {
      const confirmResult = await this.alertService.ConfirmationAlert(
        'Confirmación',
        'No has agregado parámetros al reporte. ¿Desea continuar?',
        'Continuar'
      );

      if (!confirmResult) return;
    }

    if (this.report !== null) {
      this.blockUI.Start(2);

      this.rptManagerService
        .SaveReportFile(this.report)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.Result) {
              this.HandlePostOrPutReport(report);
            } else {
              this.blockUI.Stop();
              this.alertService.ShowBigCenteredAlert(
                response.ErrorInfo.Message,
                ALERTS.error
              );
            }
          },
          (err) => {
            this.blockUI.Stop();
            this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
          }
        );
    } else {
      this.blockUI.Start(2);

      this.HandlePostOrPutReport(report);
    }
  }

  async onClickDeleteParam(paramList: ReportParameter2[], index: number) {
    let confirmResult = await this.alertService.ConfirmationAlert(
      'Confirmación',
      '¿Desea eliminar el parámetro?',
      'Eliminar'
    );
    if (confirmResult) paramList.splice(index, 1);
  }

  onClickAddParameter() {
    let parameter = this.getParameterFromForm();
    this.parameterSubgroup1.push(parameter);
    this.resetParameterForm();
    this.resetParameterValueForm();
    this.parameterOptions = null;
  }

  onClickPrintReport(reportId: number) {
    this.router.navigateByUrl(`print-report/${reportId}`);
  }

  onClickDownloadFile() {
    if (!this.reportForm.get('Id').value) return;
    this.blockUI.Start(1);
    this.rptManagerService
      .DownloadFile(Number(this.reportForm.get('Id').value))
      .subscribe((response) => {
        this.blockUI.taskFinished();
        if (response.Result) {
          this.commonService.downloadFile(
            response.Print,
            this.reportForm.get('DisplayName').value,
            'application/octet-stream',
            'rpt'
          );
        } else {
          this.blockUI.taskFinished();
          this.alertService.ShowBigCenteredAlert(
            response.ErrorInfo.Message,
            ALERTS.error
          );
        }
      });
  }

  get reportFormControls() {
    return this.reportForm.controls;
  }

  get parameterFormControls() {
    return this.parameterForm.controls;
  }

  getReportModel() {
    this.parameters = [];
    this.paramaterListGroup.forEach((list, col) => {
      list.forEach((param, row) => {
        param.GridCol = col;
        param.GridRow = row;
        this.parameters.push(param);
      });
    });

    const report: Report2 = {
      Actve: true,
      ApplicationId: 0,
      ReportUserId: Number(this.reportForm.get('ReportUserId').value),
      DisplayName: this.reportForm.get('DisplayName').value,
      Name: this.reportForm.get('Name').value,
      Id: this.reportForm.get('Id').value,
      Parameters: this.parameters,
    };

    return report;
  }

  onReportSelected($event) {
    this.report = <File>$event.target.files[0];

    if (!this.report) return;
    if (!this.commonService.isValidFile(this.report, ['rpt'])) {
      this.report = null;
      this.alertService.ShowBigCenteredAlert(
        'Archivo no soportado',
        ALERTS.warning
      );
      return;
    }

    this.reportForm.get('Name').setValue(this.report.name);
  }

  getParameterFromForm() {
    let parameter: ReportParameter2 = {
      Id: Number(this.parameterForm.get('Id').value),
      Name: this.parameterForm.get('Name').value,
      ReportId: Number(this.parameterForm.get('ReportId').value),
      Type: Number(this.parameterForm.get('Type').value),
      GridCol: 0,
      GridRow: 0,
      Options: this.parameterOptions,
      Required: this.parameterForm.get('Required').value === 'S',
    };

    return parameter;
  }

  getParameterType(type) {
    switch (type) {
      case REPORT_PARAMETER.Alpha:
        return 'Alfanumérico';
      case REPORT_PARAMETER.Boolean:
        return 'Check';
      case REPORT_PARAMETER.Date:
        return 'Fecha';
      case REPORT_PARAMETER.Numeric:
        return 'Numérico';
      case REPORT_PARAMETER.MultipleOption:
        return 'Opción múltiple';
      default:
        return '';
    }
  }

  onItemDropped(event) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  dismissModal(result: boolean) {
    this.modalService.dismissAll(result);
  }

  onTabChange() {
    if (this.activeTab === 1) {
      this.initializeApp();
    }
  }

  showParameterOptionsModal(
    modalParameterOptions: any,
    options: ParameterOption[]
  ) {
    this.parameterOptionsModal = options;
    let modal = this.modalService.open(modalParameterOptions, {
      size: 'lg',
      backdrop: true,
      scrollable: true,
    });

    modal.result.then(
      () => {},
      () => {
        options = this.parameterOptionsModal;
        this.parameterOptionsModal = null;
        console.log(options);
        console.log(this.parameterOptions);
      }
    );
  }

  onParameterTypeChange(event: any, modalParameterOptions: any) {
    if (Number(event.target.value) === REPORT_PARAMETER.MultipleOption) {
      if (!this.parameterOptions) this.parameterOptions = [];

      this.showParameterOptionsModal(
        modalParameterOptions,
        this.parameterOptions
      );
    }
  }

  onClickParameterOptions(
    parameter: ReportParameter2,
    modalParameterOptions: any
  ) {
    this.showParameterOptionsModal(modalParameterOptions, parameter.Options);
  }

  onClickAddParameterValidValue() {
    this.parameterOptionsModal.push({
      ...this.parameterValueForm.value,
      Id: 0,
      ParameterId: 0,
    });
    this.resetParameterValueForm();
  }

  onClickDeleteValidValue(index: number) {
    if (this.parameterOptionsModal.length === 1) {
      this.alertService.ShowSmallCornerAlert(
        'Debes agregar al menos una opción válida',
        ALERTS.info
      );
      return;
    }

    this.parameterOptionsModal.splice(index, 1);
  }
}
