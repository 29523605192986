import { Injectable } from '@angular/core';
import { IPermission } from '../models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;

  constructor() {
    this.localStorageService = localStorage;
   }

  setCurrentSession(currentUser): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(currentUser));
  }

  GetCurrentSession(): string {
    return this.localStorageService.getItem('currentUser');
  }

  getToken(){
    return JSON.parse(this.localStorageService.getItem('currentUser'));
  }
  
  GetToken(): void {
    return JSON.parse(this.localStorageService.getItem('currentUser')).access_token;
  }

  SetPermissions(_permissions: IPermission[]): void {
    this.localStorageService.setItem('Perms', JSON.stringify(_permissions));
  }

  GetPermissions(): IPermission[] {
    const PERMISSIONS = JSON.parse(this.localStorageService.getItem('Perms')) as IPermission[];
    if (PERMISSIONS !== null) return PERMISSIONS;
    return [];
  }

  GetThirdPartyToken(): string {
    return JSON.parse(this.localStorageService.getItem('thirdParty_token')).access_token;
  }

  SetThirdPatyToken(_thirdParty: string): void {
    this.localStorageService.setItem('thirdParty_token', JSON.stringify(_thirdParty));
  }

  FlushToken(): void {
    let SESSION = JSON.parse(this.GetCurrentSession());
    SESSION['access_token'] = '';

    this.setCurrentSession(SESSION);
  }
}
