import { Component, OnInit } from '@angular/core';
import { FileManagerService } from 'src/app/services/file-manager.service';
import { AlertService, UserService, StorageService } from 'src/app/services';
import { BlockUIHandlerService, CommonService } from 'src/app/common/services.index';
import { IMaterialManager, IUser, IRawFile, ISearch, IRuleOutFile, IFileTransaction, IProduct, IWarehouse } from 'src/app/models';
import { ALERTS } from 'src/app/common/enums';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FILE_STATUS } from 'src/app/common/constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {
  //VARBOX
  currentRawFile: IRawFile;
  isHiddenFileSelector: boolean;
  lastIndex: number;
  isCancellingCommentary: boolean;
  ComentaryFileControl: FormControl;
  globalCommentaryControl: FormControl;
  global: any;
  isExpanded: boolean;
  warehouses: IWarehouse[];
  targetProduct: IProduct;
  globalWHSControl: FormControl;
  operator: string;
  productList: IProduct[];
  rawFile: IRawFile;
  modalReference: NgbModalRef;
  users: IUser[];
  mDate: string;
  files: any[] = [];
  rawFiles: IRawFile[];
  searchForm: FormGroup;
  fileForm: FormGroup;
  previewForm: FormGroup;
  constructor(private formBuilder: FormBuilder
    , private fileManagerService: FileManagerService
    , private alertService: AlertService
    , private blockUIHandler: BlockUIHandlerService
    , private userService: UserService
    , private modalService: NgbModal
    , private storageService: StorageService
    ,private commonServices: CommonService
  ) { }

  ngOnInit(): void {
    this.InitVariables();
    this.LoadData();
  }

  OnFileDropped($event): void {
    this.global = $event;
    this.PrepareFilesList($event);
  }

  FileBrowseHandler(files): void {
    this.PrepareFilesList(files);
  }

  DeleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  PrepareFilesList(files: Array<any>): void {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }

    (<HTMLInputElement>document.getElementById('fileDropRef')).value = '';
    this.UploadFilesSimulator(0);
  }

  UploadFilesSimulator(index: number): void {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.UploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 10);
      }
    }, 10);
  }

  FormatBytes(bytes, decimals): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  UploadDocuments(): void{

    const PERMISSION = this.storageService.GetPermissions().find(x => x.Code === 'R_Upl_File');

    if (!PERMISSION) {
      this.alertService.ShowSmallCornerAlert(`No tiene permiso para subir archivos`, ALERTS.info);
      return;
    }

    if (this.files.length === 0) {
      this.alertService.ShowSmallCornerAlert(`Seleccione un archivo al menos`, ALERTS.info);
      return;
    }




    // return;

    let materials = this.files.map((x) => x as File);

    let fileWarehouses = this.files.map((x, i) => {return {
      WhsCode: (<HTMLInputElement> document.getElementById('fileWhs_' + i)).value
    } as IWarehouse});

    let commentaries = this.files.map((x, i) => (<HTMLInputElement> document.getElementById('inputCom_' + i)).value);

    let materialManager = {} as IMaterialManager;

    const CURRENT_SESSION = JSON.parse(this.storageService.GetCurrentSession());

    const USER = {
      Email: '',
      UserName: '',
      Id: this.users.find(x => x.Email === CURRENT_SESSION.UserName).Id
    } as IUser;

    materialManager.Files = materials;
    materialManager.User = USER;
    materialManager.WareHouses = fileWarehouses;
    materialManager.Commentaries = commentaries;

    let formData = new FormData();
    materials.forEach((x, i) => {
      formData.append(`file_${i}`, x);
    });

    materialManager.FilesAmout = materials.length;
    this.blockUIHandler.Start(1, `Guardando archivos`);
    formData.append(`MaterialManager`, JSON.stringify(materialManager));
    formData.append(`WhsCode`, this.globalWHSControl.value);
    this.fileManagerService.SaveDocuments(formData).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.alertService.ShowSmallCornerAlert(materials.length > 1 ? `Documentos guardados` : `Documento guardado`, ALERTS.success);
        if ((<HTMLInputElement>document.getElementById('fileDropRef'))) {
          (<HTMLInputElement>document.getElementById('fileDropRef')).value = '';
        }
        this.files = [];
        this.GetFiles();
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      this.blockUIHandler.Stop();
      console.log(error);
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  InitVariables(): void {
    this.isHiddenFileSelector = false;
    this.isExpanded = false;
    this.warehouses = [];
    this.globalWHSControl = new FormControl('0');
    this.globalCommentaryControl = new FormControl('');
    this.ComentaryFileControl = new FormControl('');
    this.productList = [];
    this.ResetSearchForm();
    //this.ResetPreviewForm();
    this.rawFiles = [];
  }

  GetFiles(): void {
    this.rawFiles = [];
    this.blockUIHandler.Start(1, `Obteniendo documentos, espere por favor`);
    this.fileManagerService.GetFiles(this.searchForm.value as ISearch).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.rawFiles = next.RawFiles;
        this.GetFilteredFiles();
      }
      else {
        this.alertService.ShowSmallCornerAlert(`No hay registros ${next?.ErrorInfo?.Message || ''}`, ALERTS.info);
        console.log(next);
      }
    }, error => {
      this.blockUIHandler.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
      console.log(error);
    });
  }

  ResetSearchForm(): void {
    this.mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.searchForm = this.formBuilder.group({
      From: [this.mDate, Validators.required],
      To: [this.mDate, Validators.required],
      UserId: ['-1'],
      FileStatus: [false]
    });
  }

  // ResetPreviewForm(): void {
  //   this.mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  //   this.previewForm = this.formBuilder.group({
  //     Date: [''],
  //     WindowsUser: [''],
  //     Operator: ['']
  //   });
  // }

  ResetFileForm(): void {
    this.fileForm = this.formBuilder.group({
      Comentary: ['', Validators.required]
    });
  }

  LoadData(): void {
    this.blockUIHandler.Start(1);
    this.userService.Get().subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.users = next.Users.filter(x => x.IsActive);
      }
      else {
        this.alertService.ShowSmallCornerAlert(`No se obtuvieron los usuarios`, ALERTS.warning);
      }
    }, error => {
      this.blockUIHandler.Stop();
      console.log(error);
      this.alertService.ShowBigCenteredAlert(error, ALERTS.error);
    });

    this.GetWarehouses();
  }

  RaiseFileModal(content, _rawFile: IRawFile): void {

    const PERMISSION = this.storageService.GetPermissions().find(x => x.Code === 'R_Del_File');

    if (!PERMISSION) {
      this.alertService.ShowSmallCornerAlert(`No tiene permiso para cancelar el archivo`, ALERTS.info);
      return;
    }

    if (_rawFile.Status === FILE_STATUS.PROCESSED) {
      this.alertService.ShowSmallCornerAlert(`El archivo ha sido procesado`, ALERTS.info);
      return;
    }

    if (_rawFile.Status === FILE_STATUS.DELETED) {
      this.alertService.ShowSmallCornerAlert(`El archivo ha sido cancelado`, ALERTS.info);
      return;
    }

    this.isCancellingCommentary = true;

    this.ResetFileForm();
    this.modalReference = this.modalService.open(content,
      {
        centered: true,
        backdrop: 'static'
      });
    this.rawFile = _rawFile;
  }

  TriggerComentaryAction(): void {
    if (this.isCancellingCommentary) {
      if (!this.fileForm.valid) {
        this.alertService.ShowSmallCornerAlert(`El comentario es requerido para cancelar el archivo`, ALERTS.info);
        return;
      }

      const CURRENT_SESSION = JSON.parse(this.storageService.GetCurrentSession())

      const USER = this.users.find(x => x.Email === CURRENT_SESSION.UserName);

      const RULE_OUT_FILE = {
        Comment: this.fileForm.get('Comentary').value,
        Id: this.rawFile.Id,
        Path: this.rawFile.InternalName,
        UserId: USER.Id
      } as IRuleOutFile;

      this.blockUIHandler.Start(1, `Cancelando archivo, espere por favor`);

      this.fileManagerService.DeleteFile(RULE_OUT_FILE).subscribe(next => {
        this.blockUIHandler.Stop();
        if (next.Result) {
          this.alertService.ShowSmallCornerAlert(`Archivo cancelado`, ALERTS.success);
          this.CloseModal();
          this.GetFiles();
        }
        else {
          this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
        }
      }, error => {
        console.log(error);
        this.blockUIHandler.Stop();
        this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
      });
    }
    else {
      (<HTMLInputElement> document.getElementById(`inputCom_${this.lastIndex}`)).value = this.fileForm.get('Comentary').value;
      this.CloseModal();
    }
  }

  CloseModal(): void {
    this.ResetFileForm();
    this.modalReference.close();
  }

  GetEmail(_id: number): string {
    const USER = this.users?.find(x => x.Id === _id);
    if (USER) return USER.Email;

    return 'USUARIO NO ENCONTRADO';
  }

  GetUserId(_email: string): number {
    return this.users.find(x => x.Email === _email).Id;
  }

  async ProcessFile(_index: number): Promise<void> {

    const PERMISSION = this.storageService.GetPermissions().find(x => x.Code === 'R_Pro_File');

    if (!PERMISSION) {
      this.alertService.ShowSmallCornerAlert(`No tiene permiso para procesar el archivo`, ALERTS.info);
      return;
    }

    const FILE = this.rawFiles.find(x => x.Id === _index);

    if (!FILE) {
      this.alertService.ShowSmallCornerAlert(`Archivo no encontrado`, ALERTS.info);
      return;
    }

    if (FILE.Status === FILE_STATUS.DELETED) {
      this.alertService.ShowSmallCornerAlert(`Este archivo ha sido cancelado`, ALERTS.info);
      return;
    }

    if (FILE.Status === FILE_STATUS.PROCESSED) {
      this.alertService.ShowSmallCornerAlert(`Este archivo ha sido procesado`, ALERTS.info);
      return;
    }

    const  RESULT = await this.alertService.ConfirmationAlert(`Se procesará el archivo`, `¿ Desea continuar ?`, `Continuar`);

    if (!RESULT) {
      return;
    }

    const FILE_TRANSACTION = {
      Event: 'PROCESS_FILE',
      IdFile: _index,
      IdUser: this.GetUserId(JSON.parse(this.storageService.GetCurrentSession()).UserName)
    } as IFileTransaction;

    this.blockUIHandler.Start(1, `Procesando archivo en sap, espere por favor`);
    this.fileManagerService.ProcessFile(FILE_TRANSACTION).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        if (next.ErrorInfo) {
          this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message.slice(0, -2)}`, ALERTS.warning);
        }
        else {
          this.alertService.ShowSmallCornerAlert(`Archivo procesado`, ALERTS.success);
        }
        this.GetFiles();
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUIHandler.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  GetFilteredFiles(): IRawFile[] {
    if (this.searchForm.get('FileStatus').value) {
      return this.rawFiles.filter(x => x.Status === FILE_STATUS.DELETED);
    }
    return this.rawFiles.filter(x => x.Status !== FILE_STATUS.DELETED);
  }

  DownloadDocument(_rawFile: IRawFile): void {
    const FILE_TRANSACTION = {
      Event: 'DOWNLOAD',
      IdFile: _rawFile.Id,
      IdUser: +JSON.parse(this.storageService.GetCurrentSession()).Id
    } as IFileTransaction;
    this.fileManagerService.DownloadFile(FILE_TRANSACTION).subscribe(next => {
      if (next.Result) {
        this.DownloadFile(next.File.Base64, next.File.Name, next.File.Type.split('/')[1]);
      }
      else {
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo?.Message}`, ALERTS.warning);
      }
    }, error => {
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  DownloadFile(base64File: string, fileName: string, fileExtension: string): void {
    let report = new Blob([this.StringToArrayBuffer(atob(base64File))], {
      // type: blobType,
    });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private StringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;
    return buf;
  }

  GetFileStatus(_status: number): string {
    let parsedStatus = 'Con error';
    switch (_status) {
      case 0: {
        parsedStatus = 'Registrado'
        break;
      }
      case 1: {
        parsedStatus = 'Pendiente procesar'
        break;
      }
      case 2: {
        parsedStatus = 'Procesado'
        break;
      }
      case 3: {
        parsedStatus = 'Cancelado'
        break;
      }
    }

    return parsedStatus;
  }

  PreviewXML(_index: number, content_lists): void {

    const FILE = this.rawFiles.find(x => x.Id === _index);

    this.rawFile = FILE;

    if (!FILE) {
      this.alertService.ShowSmallCornerAlert(`Archivo no encontrado`, ALERTS.info);
      return;
    }

    if (FILE.Status === FILE_STATUS.DELETED) {
      this.alertService.ShowSmallCornerAlert(`Este archivo ha sido cancelado`, ALERTS.info);
      return;
    }

    const FILE_TRANSACTION = {
      Event: 'PROCESS_FILE',
      IdFile: _index,
      IdUser: this.GetUserId(JSON.parse(this.storageService.GetCurrentSession()).UserName)
    } as IFileTransaction;

    this.blockUIHandler.Start(1, `Generando previzualización, espere por favor`);
    this.fileManagerService.PreviewXML(FILE_TRANSACTION).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.operator = next.PlantCommunication._Operator;
        this.productList = next.PlantCommunication.Products;
        this.isExpanded = false;
        this.productList.forEach(x => x.IsHidden = true);
        this.globalWHSControl.setValue(next.PlantCommunication.WhsCode);
        this.globalCommentaryControl.setValue(next.PlantCommunication.Comment);

        this.modalReference = this.modalService.open(content_lists, {
            centered: true,
            backdrop: 'static',
            size: 'xl',
            scrollable: true
          });
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
      }
    }, error => {
      console.log(error);
      this.blockUIHandler.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  ToggleView(_product: IProduct): void {
    if (!this.productList.find(x => x.AccountCode === _product.AccountCode).IsHidden) {
      this.productList.find(x => x.AccountCode === _product.AccountCode).IsHidden = true;
    }
    else {
      this.productList.find(x => x.AccountCode === _product.AccountCode).IsHidden = false;
    }
  }

  ToggleExpand(): void {
    this.productList.forEach(x => x.IsHidden = this.isExpanded);
    this.isExpanded = !this.isExpanded;
  }

  UpdateGlobalWHS(data): void {
  }

  UpdateWHS(): void {
    const PERMISSION = this.storageService.GetPermissions().find(x => x.Code === 'W_Cha_Whs');

    if (!PERMISSION) {
      this.alertService.ShowSmallCornerAlert(`No tiene permiso para actualizar`, ALERTS.info);
      return;
    }

    this.rawFile.WhsCode = this.globalWHSControl.value;

    const CURRENT_SESSION = JSON.parse(this.storageService.GetCurrentSession());

    this.blockUIHandler.Start(1, `Actualizando, espere por favor`);

    this.fileManagerService.UpdateWhs(this.rawFile.Id, this.users.find(x => x.Email === CURRENT_SESSION.UserName).Id, this.globalWHSControl.value, this.globalCommentaryControl.value).subscribe(next => {
      this.blockUIHandler.Stop();
        if (next.Result) {
          this.alertService.ShowSmallCornerAlert(`Almacén actualizado`, ALERTS.success);
          this.modalReference.close();
          this.GetFiles();
        }
        else {
          this.alertService.ShowBigCenteredAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
        }
    }, error => {
      console.log(error);
      this.blockUIHandler.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  GetWarehouses(): void {
    this.commonServices.GetWarehouses().subscribe(next => {
      if (next.Result) {
        this.warehouses = next.WareHouses;
        this.globalWHSControl.setValue(this.warehouses[0].WhsCode);
      }
      else {
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo?.Message}`, ALERTS.warning);
      }
    }, error => {
      console.log(error);
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  MappedCommentary(_commentary: string): string {
    if (!_commentary) return '';
    if (_commentary.length > 20) {
      return _commentary.slice(0, 20);
    }
    else {
      return _commentary;
    }
  }

  PreviewCommentary(_rawFile: IRawFile, content): void {
    this.currentRawFile = _rawFile;
    this.modalService.open(content,
      {
        centered: true,
        backdrop: 'static'
      });
  }

  RaiseCustomMessage(_index: number, content): void {
    this.isCancellingCommentary = false;
    this.lastIndex = _index;

    this.fileForm = this.formBuilder.group({
      Comentary: [(<HTMLInputElement> document.getElementById(`inputCom_${_index}`)).value]
    });

    this.modalReference = this.modalService.open(content,
      {
        centered: true,
        backdrop: 'static'
      });
  }

  MaximizeFileSelector(): void {
    this.isHiddenFileSelector = false;
  }

  MinimizeFileSelctor(): void {
    this.isHiddenFileSelector = true;
  }
}
