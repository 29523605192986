import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants, BaseResponse } from '../common/models.index';
import { SessionService } from './session.service';
import { IRawFilesResopnse, IFileResponse, IMaterialListResponse } from '../models/responses';
import { ISearch, IRuleOutFile, IFileTransaction } from '../models';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {

  constructor(
    private sessionService: SessionService
    ,private httpClient: HttpClient
  ) { }

  // GetFiles(onlyParentRequirements: boolean) {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Origin': '*',
  //     Authorization: `Bearer ${this.sessionService.session.Token}`,
  //   });

  //   // return this.http.get<RequirementsResponse>(
  //   //   `${AppConstants.API_URL}api/Requirement/GetRequirements?onlyParentRequirements=${onlyParentRequirements}`,
  //   //   { headers }
  //   // );
  // }

  SaveDocuments(_data: FormData): Observable<BaseResponse> {
    let headers = new HttpHeaders();

    headers.append('Content-Type', 'application/json');
    headers.append(`Authorization`, `Bearer ${this.sessionService.session.Token}`);

    const httpOptions = {
        headers: headers
    };

    return this.httpClient.post < BaseResponse > (`${environment.ApiUrl}api/MaterialManager/SaveMaterials`, _data, httpOptions);
  }

  GetFiles(_search: ISearch): Observable<IRawFilesResopnse> {

    const url = `${environment.ApiUrl}api/FileManager/GetFiles`;

    return this.httpClient.post<IRawFilesResopnse>(url, _search);
  }

  DeleteFile(_ruleOutFile: IRuleOutFile): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/FileManager/DeleteFile`;

    return this.httpClient.post<IRawFilesResopnse>(url, _ruleOutFile, { headers });
  }

  ProcessFile(_fileTransaction: IFileTransaction): Observable<BaseResponse> {
    const url = `${environment.ApiUrl}api/FileManager/ProcessFile`;
    return this.httpClient.post<BaseResponse>(url, _fileTransaction);
  }

  DownloadFile(_fileTransaction: IFileTransaction): Observable<IFileResponse> {
    const url = `${environment.ApiUrl}api/FileManager/DownloadFile`;
    return this.httpClient.post<IFileResponse>(url, _fileTransaction);
  }

  PreviewXML(_fileTransaction: IFileTransaction): Observable<IMaterialListResponse> {
    const url = `${environment.ApiUrl}api/FileManager/PreviewXML`;
    return this.httpClient.post<IMaterialListResponse>(url, _fileTransaction);
  }

  UpdateWhs(_fileId: number, _userId: number, _whsCode: string, _commentary: string): Observable<BaseResponse> {
    const url = `${environment.ApiUrl}api/FileManager/UpdateWhs`;
    return this.httpClient.post<BaseResponse>(url, {
      "Item1": _fileId,
      "Item2": _userId,
      "Item3": _whsCode,
      "Item4": _commentary
    });
  }
}
