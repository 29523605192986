export enum GENERIC_VALUES {
  BUSINESS_PARTNER = 1,
  SALESPEOPLE,
  PATIENT,
  SEDE,
}

export enum ALERTS {
  success = 1,
  error = 2,
  warning = 3,
  info = 4,
}

export enum TRANSACT_TYPE {
  CLOSE = 0,
  OPEN = 1,
  ANY = 2,
}

export enum DOCTYPE {
  INVOICE = 13,
}

export enum REPORT_PARAMETER {
  Alpha = 1,
  Numeric,
  Date,
  Boolean,
  MultipleOption,
}
