import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ALERTS } from '../common/enums';
import { AlertService } from './alerts.service';
import { SessionService } from './session.service';
import { SideBarService } from './side-bar.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public sessionService: SessionService
    , public sidebarService: SideBarService
    , private alertService: AlertService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401) {
          const MESSAGE = `Lo sentimos, la sesión ha expirado`;
          this.sidebarService.currentPath.next('Inicio');
          this.sidebarService.toggler.next(false);
          this.sessionService.logOut();

          return throwError(MESSAGE);
        }

        if (error.status === 500) {
          return throwError(error);
        }

        let errorMsg = '';
        if (error.error && error.error instanceof ErrorEvent) {
          errorMsg = error.error.message;
        } else {
          errorMsg = error?.error?.error_description || error.message;
        }

        return throwError(errorMsg);
      })
    );
  }
}
