<block-ui>


    <div class="row p-1">
        <div class="col-sm-3 offset-sm-9">
            <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="RaiseUserModal(content)"><i class="fa fa-plus"
                    aria-hidden="true"></i> Agregar usuario</button>
        </div>
    </div>
    <div class="row mt-2">
        <table class="table table-striped mt table-sm table-hover">
            <thead style="background-color: #172749; color: white;">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Usuario</th>
                    <th scope="col">Correo</th>
                    <th scope="col" class="text-center">Activo</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of users; let i = index" class="p-0">
                    <th scope="row">{{(i + 1)}}</th>
                    <td>{{user.UserName}}</td>
                    <td>{{user.Email}}</td>
                    <td class="text-center"><i class="fa fa-{{user.IsActive ? 'check': 'times'}}"></i></td>
                    <td class="justify-content-center option clasesita">
                        <i class="fa fa-edit" style="cursor: pointer;" title="Editar usuario" (click)="GetUser(user.Id, content)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title">Agregar usuario</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="userForm">
                <div class="form-group" style="display: none;">
                    <label for="">Correo electrónico</label>
                    <input type="email" class="form-control form-control-sm" aria-describedby="emailHelp" formControlName="Id">
                    <!-- <small class="form-text text-muted text-danger">We'll never share your email with anyone else.</small> -->
                </div>
                <div class="form-group">
                    <label for="">Correo electrónico</label>
                    <input type="email" class="form-control form-control-sm" aria-describedby="emailHelp" formControlName="Email">
                    <!-- <small class="form-text text-muted text-danger">We'll never share your email with anyone else.</small> -->
                </div>
                <div class="form-group">
                    <label for="">Nombre de usuario</label>
                    <input type="email" class="form-control form-control-sm" aria-describedby="emailHelp" formControlName="UserName">
                    <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Contraseña</label>
                    <input type="password" class="form-control form-control-sm" formControlName="Password">
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="IsActive" style="transform: scale(1.5);">
                    <label class="form-check-label" for="exampleCheck1">Activo</label>
                </div>
            </form>

            <div class="row">
                <div class="col-sm-4 offset-sm-4">

                    <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st float-right" (click)="Store()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Guardar</button>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="CloseModal()"><i class="fa fa-times-circle" aria-hidden="true"></i> Cerrar&nbsp;</button>

                </div>
            </div>
        </div>
    </ng-template>
</block-ui>
