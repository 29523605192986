import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, min } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import {
  BPInfoLogResponse, BusinessPartnerInfoResponse, ManualSendReportBPResponse, GetPdfReportResponse, Company, SearchBusinessPartnerLogModel,
  BPInfoLog
} from 'src/app/models'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, SearchService, ManualSendService, ConfigParamsService, AuthenticationService } from 'src/app/services'
import { sendTypes } from 'src/app/common/constants';
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-supplierlog',
  templateUrl: './supplierlog.component.html',
  styleUrls: ['./supplierlog.component.css']
})
export class SupplierlogsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public searchSupplierForm: FormGroup;
  public BusinessPartnerFieldForm: FormGroup;
  public logSupplierForm: FormGroup;
  public BusinessPartnerFieldFormSenderReport: FormGroup;

  infoSearchBPReport: SearchBusinessPartnerLogModel; // obj almacena la info para busqueda reporte por proveedor
  BPInfoLog: Array<BPInfoLog> = []; // obj almacena la info de logs consultada
  BpNameList: string[] = []; // almacena la lista de los proveedores traidos de sap
  BpNameListSenderReport: string[] = []; // almacena la lista de los proveedores traidos de sap en el tab de Enviar reportes
  companyList: Array<Company>; // almacena lista de compañias que se utiliza para cargar dropdown
  managerCompanyList: Array<any> = [];
  optionSendContainer: boolean = false; // habilita los botones de envio reporte proveedor y ver reporte
  optionByManager: boolean = false; // habilita el boton de ver reporte

  statusLog: { key: string, value: string }[] = [
    { key: '0', value: 'Todos' },
    { key: '1', value: 'Pendiente' },
    { key: '2', value: 'Enviando' },
    { key: '3', value: 'Enviado' },
    { key: '4', value: 'Error' }
  ];



  errorMessage: string; // variable para el mensaje en caso de error

  @ViewChild('instanceSearchBP', { static: false }) instanceSearchBP: NgbTypeahead;
  focusSearchBP$ = new Subject<string>();
  clickSearchBP$ = new Subject<string>();

  @ViewChild('instanceSearchBPSendReport', { static: false }) instanceSearchBPSendReport: NgbTypeahead;
  focusSearchBPSendReport$ = new Subject<string>();
  clickSearchBPSendReport$ = new Subject<string>();
  Today: any;
  date: Date = new Date(); // fecha actual
  closeResult = '';

  constructor(private fb: FormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private ManualSendService: ManualSendService,
    private configParamsService: ConfigParamsService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.ngLoad();
  }

  ngLoad() {
    this.Today = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;

    this.logSupplierForm = this.fb.group({
      startDate: [this.Today, Validators.required],
      endDate: [this.Today, Validators.required],
      companyId: ['', [Validators.required]],
      CardCode: [''],
      Status: [this.statusLog[0].key, Validators.required]
    });

    this.searchSupplierForm = this.fb.group({
      sendType: [{ value: '', disabled: true }, Validators.required],
      startDate: [{ value: this.Today, disabled: true }, Validators.required],
      endDate: [{ value: this.Today, disabled: true }, Validators.required],
      companyId: ['', [Validators.required]],
      CardCodeSenderReport: ['']
    });

    this.BusinessPartnerFieldForm = this.fb.group({
      CardCode: ['', Validators.required]
    });

    this.BusinessPartnerFieldFormSenderReport = this.fb.group({
      CardCodeSenderReport: [{ value: '', disabled: true }, Validators.required]
    });

    this.getCompaniesList();
  }

  get companyIdbp() {
    return this.searchSupplierForm.get('companyId');
  }
  get companyIdbpLog() {
    return this.logSupplierForm.get('companyId');
  }

  // Typeahead
  searchBP = (text$: Observable<string>) => {
    console.log('TypeAhead');
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickSearchBP$.pipe(filter(() => !this.instanceSearchBP.isPopupOpen()));
    const inputFocus$ = this.focusSearchBP$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.BpNameList
        : this.BpNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  // Typeahead
  searchBPSenderReport = (text$: Observable<string>) => {
    console.log('TypeAhead');
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickSearchBPSendReport$.pipe(filter(() => !this.instanceSearchBPSendReport.isPopupOpen()));
    const inputFocus$ = this.focusSearchBPSendReport$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.BpNameListSenderReport
        : this.BpNameListSenderReport.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  getBPListFromSAP() {
    console.log('getBPListFromSAP');
    if (Number(this.logSupplierForm.controls['companyId'].value) > 0) {
      this.BpNameList = [];
      this.blockUI.start('Obteniendo lista de proveedores.');
      console.log('Obteniendo lista de proveedores');
      this.searchService.getBusinessPartnerFromSap(this.logSupplierForm, 'S')
        .subscribe((data: BusinessPartnerInfoResponse) => {
          console.log(data);
          console.log(data.Result);
          if (data.Result) {
            this.BpNameList = data.BusinessPartnerInfoList.map((x) => x.FullName);
            this.blockUI.stop();

          } else {
            this.BpNameList = [];  
            this.blockUI.stop(); // Stop blocking
            this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
          }
        }, (error: any) => {
          console.log(error);
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
        });
    }
    else {
      this.BpNameList = [];      
    }
  }

  getBPListFromSAPSenderReport(sendType: string) {
    if (Number(this.searchSupplierForm.controls['companyId'].value) > 0) {
      this.BpNameListSenderReport = [];
      this.blockUI.start('Obteniendo lista de proveedores.');
      console.log('Obteniendo lista de proveedores');
      this.searchService.getSuppliersFromSap(this.searchSupplierForm, 'S', sendType)
        .subscribe((data: BusinessPartnerInfoResponse) => {
          console.log(data);
          console.log(data.Result);
          if (data.Result) {
            this.BpNameListSenderReport = data.BusinessPartnerInfoList.map((x) => x.FullName);
            this.blockUI.stop();

          } else {
            this.blockUI.stop(); // Stop blocking
            this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
          }
        }, (error: any) => {
          console.log(error);
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
        });
    }
    else {
      this.BpNameListSenderReport = [];
      this.disableElements();
    }
  }

  getCompaniesList() {
    const currentUser = this.authenticationService.currentUserValue;
    this.blockUI.start('Buscando compañías del gestor');
    console.log('Buscando compañías gestor');
    this.configParamsService.getCompaniesList()
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.managerCompanyList = data.CompanyList;
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getSuppliersLogs() {
    this.BPInfoLog = [];
    this.blockUI.start('Obteniendo reportes de proveedores.');
    console.log('Obteniendo logs de proveedores');
    this.searchService.getSuppliersLogs(this.logSupplierForm)
      .subscribe((data: BPInfoLogResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.BPInfoLog = data.BPInfoLogList;
          this.blockUI.stop();

        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  sendManualReportSupplier() {
    this.blockUI.start('Enviando reporte por proveedor');
    console.log('Enviando reporte por proveedor');
    this.ManualSendService.sendManualReportSupplier(this.searchSupplierForm, this.BusinessPartnerFieldFormSenderReport)
      .subscribe((data: ManualSendReportBPResponse) => {
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Reporte enviado`, ALERTS.success);
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
        //this.getSuppliersLogs();
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  // funcion para la visualizacion del archivo
  viewDocument() {
    this.blockUI.start('Generando reporte'); // Start blocking
    this.searchService.GetPdfReportSupplier(this.searchSupplierForm, this.BusinessPartnerFieldFormSenderReport)
      .subscribe((data: GetPdfReportResponse) => {
        if (data.Result) {
          this.blockUI.stop(); // Stop blocking
          console.log(data);
          var fileBase64 = atob(data.PdfBase64);
          var length = fileBase64.length;
          var arrayBuffer = new ArrayBuffer(length);
          var uintArray = new Uint8Array(arrayBuffer);
          for (var i = 0; i < length; i++) {
            uintArray[i] = fileBase64.charCodeAt(i);
          }
          var tab = window.open();
          var pdfFile = new Blob([uintArray], { type: 'application/pdf' });
          var fileUrl = URL.createObjectURL(pdfFile);
          tab.location.href = fileUrl;
        }
        else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        console.log('error');
        console.log(error);
        this.alertService.ShowBigCenteredAlert(`${error.Message}`, ALERTS.error); 
      });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  disableElements() {
    this.searchSupplierForm.controls['sendType'].setValue('');
    this.searchSupplierForm.controls['sendType'].disable();
    this.searchSupplierForm.controls['startDate'].disable();
    this.searchSupplierForm.controls['endDate'].disable();
    this.optionSendContainer = false;
  }

  enableSendType(optionSelected: string) {
    if (optionSelected != '') {
      this.searchSupplierForm.controls['sendType'].setValue('');
      this.searchSupplierForm.controls['sendType'].enable();
      this.optionSendContainer = false;
    }
    else {
      this.disableElements();
    }
    this.BpNameList = [];
    this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].disable();
    this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].setValue('');
  }

  sendTypeSelected(optionSelected: string) {
    console.log('sendTypeSelected');
    switch (optionSelected) {
      case sendTypes.SinSeleccion:
        this.optionSendContainer = false;
        this.searchSupplierForm.controls['startDate'].disable();
        this.searchSupplierForm.controls['endDate'].disable();        
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].setValue('');
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].disable();
        this.BpNameListSenderReport = [];
        break;
      case sendTypes.RangoPorFecha:
        this.searchSupplierForm.controls['sendType'].enable();
        this.searchSupplierForm.controls['startDate'].enable();
        this.searchSupplierForm.controls['endDate'].enable();  
        this.optionSendContainer = true;
        this.optionByManager = false;
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].disable();
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].setValue('');      
        this.getBPListFromSAPSenderReport(sendTypes.RangoPorFecha)
        break;
      case sendTypes.Todos:
        this.searchSupplierForm.controls['sendType'].enable();
        this.searchSupplierForm.controls['startDate'].enable();
        this.searchSupplierForm.controls['endDate'].enable(); 
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].disable();
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].setValue('');
        this.optionSendContainer = true;
        this.optionByManager = false;
        this.getBPListFromSAPSenderReport(sendTypes.Todos)
        break;
      case sendTypes.PorProveedor:
        this.searchSupplierForm.controls['sendType'].enable();
        this.searchSupplierForm.controls['startDate'].enable();
        this.searchSupplierForm.controls['endDate'].enable(); 
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].enable();
        this.BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].setValue('');
        this.optionSendContainer = true;
        this.optionByManager = true;
        this.getBPListFromSAPSenderReport(sendTypes.PorProveedor)
        break;
      default:
        break;
    }
  }

  changeDate() {
    this.getBPListFromSAPSenderReport(sendTypes.RangoPorFecha);
  }
}