import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";

// MODELOS
import {
  CompanyModel, CompanyModelResponse, IAlert, AlertToCompanyModelResponse,
  AssignAlertToCompany, CompanyAlertListResponse, UpdateCompanyModel
} from './../../models/index'
import { Form, FormGroup } from '@angular/forms';
import { CompaniesResponse, AlertListResponse, SapConnResponse, FileResponse } from './../../models/responses';
import { AppConstants} from 'src/app/common/constants'

// RUTAS
// COMPONENTES
// SERVICIOS
import { StorageService } from 'src/app/services/storage.service'
import { Observable } from 'src/app/common/angular.index';

@Injectable({
  providedIn: 'root'
})
export class ConfigParamsService {

  constructor(private http: HttpClient,
    private storageService: StorageService) { }

  // // obtiene lista de companias
  // getCompanyList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Company/GetCompanyList`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<CompaniesResponse>(URL,{ headers });
  // }

  getCompanyList(): Observable<CompaniesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Company/GetCompanyList`;

    return this.http.get<CompaniesResponse>(url, { headers });
  }

  // // obtiene lista de companias
  // getSapConnList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/GetSapConn`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<SapConnResponse>(URL,{ headers });
  // }

  getSapConnList(): Observable<SapConnResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/ConfigParams/GetSapConn`;

    return this.http.get<SapConnResponse>(url, { headers });
  }

  // // obtiene lista de alertas
  // getAlertList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Alert/GetAlerts`;
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<AlertListResponse>(URL,{ headers });
  // }

  getAlertList(): Observable<AlertListResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Alert/GetAlerts`;

    return this.http.get<AlertListResponse>(url, { headers });
  }

  // // obtiene lista de alertas+compania
  // getCompanyAlertList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/GetCompanyAlertList`;
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<CompanyAlertListResponse>(URL,{ headers });
  // }

  getCompanyAlertList(): Observable<CompanyAlertListResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/ConfigParams/GetCompanyAlertList`;

    return this.http.get<CompanyAlertListResponse>(url, { headers });
  }

  // // envia la info de la compania para crearla
  // createCompany(CreateCompanyForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   const CompanyModel: CompanyModel = {
  //     Id: 0,
  //     DbCode: CreateCompanyForm.controls['dbCode'].value,
  //     CompanyName: CreateCompanyForm.controls['companyName'].value,
  //     SAPConnectionId: CreateCompanyForm.controls['sapConnectionId'].value,
  //     //SAPServer: CreateCompanyForm.controls['sapServer'].value,
  //     Active: CreateCompanyForm.controls['active'].value,
  //     ReportPathCompany: CreateCompanyForm.controls['companyReportName'].value,
  //     ReportPathBusinessPartner: CreateCompanyForm.controls['businessPartnerReportName'].value,
  //     ReportPathSupplier: CreateCompanyForm.controls['supplierReportName'].value,
  //     EmailTo: CreateCompanyForm.controls['emailTo'].value,
  //     EmailCC: CreateCompanyForm.controls['emailCc'].value,
  //     InternalEmailReport: CreateCompanyForm.controls['InternalEmailReport'].value
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<CompanyModelResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/CreateCompany`,
  //   CompanyModel,
  //     { headers }
  //   );
  // }

  createCompany(CreateCompanyForm: FormGroup, Signature: File, CustomerReport: File, SupplierReport: File, companyReport: File): Observable<CompanyModelResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    //   // 'Authorization': `Bearer ${token.access_token}`
    // });

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };

    const CompanyModel: CompanyModel = {
      Id: 0,
      DbCode: CreateCompanyForm.controls['dbCode'].value,
      CompanyName: CreateCompanyForm.controls['companyName'].value,
      SAPConnectionId: CreateCompanyForm.controls['sapConnectionId'].value,
      //SAPServer: CreateCompanyForm.controls['sapServer'].value,
      Active: CreateCompanyForm.controls['active'].value,
      ReportPathCompany: null,//CreateCompanyForm.controls['companyReportName'].value,
      ReportPathBusinessPartner: null, //CreateCompanyForm.controls['businessPartnerReportName'].value,
      ReportPathSupplier: null, //CreateCompanyForm.controls['supplierReportName'].value,
      EmailTo: CreateCompanyForm.controls['emailTo'].value,
      EmailCC: CreateCompanyForm.controls['emailCc'].value,
      InternalEmailReport: CreateCompanyForm.controls['InternalEmailReport'].value,
      SignatureFilePath: null
    }

    let formData = new FormData();
    formData.append("CompanyInfo", JSON.stringify(CompanyModel));

    if (Signature && Signature.size > 0) {
      formData.append("Signature", Signature);
    };
    if (CustomerReport && CustomerReport.size > 0) {
      formData.append("CustomerReport", CustomerReport);
    };
    if (SupplierReport && SupplierReport.size > 0) {
      formData.append("SupplierReport", SupplierReport);
    };
    if (companyReport && companyReport.size > 0) {
      formData.append("CompanyReport", companyReport);
    };
    const url = `${environment.ApiUrl}api/ConfigParams/CreateCompany`;
    return this.http.post<AlertToCompanyModelResponse>(url, formData, httpOptions);

    // const url = `${AppConstants.API_URL}api/ConfigParams/CreateCompany`;

    // return this.http.post<CompanyModelResponse>(url, CompanyModel, { headers });
  }

  // // envia la info de la compania para crearla
  // updateCompany( companyInEdition: CompanyModel, CreateCompanyForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   console.log("updateCompanyService");
  //   const CompanyModel: UpdateCompanyModel = {
  //     Id: companyInEdition.Id,
  //     DbCode: CreateCompanyForm.controls['dbCode'].value,
  //     CompanyName: CreateCompanyForm.controls['companyName'].value,
  //     SAPConnectionId: CreateCompanyForm.controls['sapConnectionId'].value,
  //     Active: CreateCompanyForm.controls['active'].value,
  //     ReportPathCompany: CreateCompanyForm.controls['companyReportName'].value,
  //     ReportPathBusinessPartner: CreateCompanyForm.controls['businessPartnerReportName'].value,
  //     ReportPathSupplier: CreateCompanyForm.controls['supplierReportName'].value,
  //     EmailTo: CreateCompanyForm.controls['emailTo'].value,
  //     EmailCC: CreateCompanyForm.controls['emailCc'].value,
  //     InternalEmailReport: CreateCompanyForm.controls['InternalEmailReport'].value
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<CompanyModelResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/UpdateCompany`,
  //   CompanyModel,
  //     { headers }
  //   );
  // }

  updateCompany(companyInEdition: CompanyModel, CreateCompanyForm: FormGroup, Signature: File, CustomerReport: File, SupplierReport: File, CompanyReport: File): Observable<CompanyModelResponse> {
    // // const token = JSON.parse(this.storage.getCurrentSession());
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    //   // 'Authorization': `Bearer ${token.access_token}`
    // });
    debugger;
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    const httpOptions = {
      headers: headers
    };

    const CompanyModel: UpdateCompanyModel = {
      Id: companyInEdition.Id,
      DbCode: CreateCompanyForm.controls['dbCode'].value,
      CompanyName: CreateCompanyForm.controls['companyName'].value,
      SAPConnectionId: CreateCompanyForm.controls['sapConnectionId'].value,
      Active: CreateCompanyForm.controls['active'].value,
      ReportPathCompany: null, //CreateCompanyForm.controls['companyReportName'].value,
      ReportPathBusinessPartner: null, //CreateCompanyForm.controls['businessPartnerReportName'].value,
      ReportPathSupplier: null, //CreateCompanyForm.controls['supplierReportName'].value,
      EmailTo: CreateCompanyForm.controls['emailTo'].value,
      EmailCC: CreateCompanyForm.controls['emailCc'].value,
      InternalEmailReport: CreateCompanyForm.controls['InternalEmailReport'].value,
      SignatureFilePath: null
    }

    let formData = new FormData();
    formData.append("CompanyInfo", JSON.stringify(CompanyModel));

    if (Signature && Signature.size > 0) {
      formData.append("Signature", Signature);
    };
    if (CustomerReport && CustomerReport.size > 0) {
      formData.append("CustomerReport", CustomerReport);
    };
    if (SupplierReport && SupplierReport.size > 0) {
      formData.append("SupplierReport", SupplierReport);
    };
    if (CompanyReport && CompanyReport.size > 0) {
      formData.append("CompanyReport", CompanyReport);
    };
    const url = `${environment.ApiUrl}api/ConfigParams/UpdateCompany`;
    return this.http.post<AlertToCompanyModelResponse>(url, formData, httpOptions);

    // const url = `${AppConstants.API_URL}api/ConfigParams/UpdateCompany`;

    // return this.http.post<CompanyModelResponse>(url, CompanyModel, { headers });
  }

  // // envia la info de la alerta para crearla
  // updateAlert(AlertModel: IAlert, createAlertForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   console.log("createAlert");
  //   AlertModel.EmailBody = createAlertForm.controls['emailBody'].value;
  //   AlertModel.EmailSubject = createAlertForm.controls['emailSubject'].value;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<AlertToCompanyModelResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/UpdateAlert`,
  //   AlertModel,
  //     { headers }
  //   );
  // }

  updateAlert(AlertModel: IAlert, createAlertForm: FormGroup): Observable<AlertToCompanyModelResponse> {
// const token = JSON.parse(this.storage.getCurrentSession());
const headers = new HttpHeaders({
  'Content-Type': 'application/json'
  // 'Authorization': `Bearer ${token.access_token}`
});

AlertModel.EmailBody = createAlertForm.controls['emailBody'].value;
AlertModel.EmailSubject = createAlertForm.controls['emailSubject'].value;

const url = `${environment.ApiUrl}api/ConfigParams/UpdateAlert`;

return this.http.post<AlertToCompanyModelResponse>(url, AlertModel, { headers });
  }

  // // envia la info de la alerta para crearla
  // createAssignAlertToCompany(createAlertForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   console.log("createAssignAlertToCompany");
  //   const assignInfo: AssignAlertToCompany = {
  //     CompanyId: createAlertForm.controls['companyId'].value,
  //     AlertId: createAlertForm.controls['alertId'].value
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<AlertToCompanyModelResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/AssignAlertToCompany`,
  //   assignInfo,
  //     { headers }
  //   );
  // }

  createAssignAlertToCompany(createAlertForm: FormGroup): Observable<AlertToCompanyModelResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const assignInfo: AssignAlertToCompany = {
      CompanyId: createAlertForm.controls['companyId'].value,
      AlertId: createAlertForm.controls['alertId'].value
    }

    const url = `${environment.ApiUrl}api/ConfigParams/AssignAlertToCompany`;

    return this.http.post<AlertToCompanyModelResponse>(url, assignInfo, { headers });
  }

  //#region PROVEEDOR
  // // obtiene lista de companias
  // getManagerList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Manager/GetManagers`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<any>(URL,{ headers });
  // }

  getManagerList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Manager/GetManagers`;

    return this.http.get<any>(url, { headers });
  }

  // // obtiene lista de correos de los gestores
  // getEmailManagerList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL}api/ConfigParams/GetEmailManagerConfigList`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<any>(URL,{ headers });
  // }

  getEmailManagerList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/ConfigParams/GetEmailManagerConfigList`;

    return this.http.get<any>(url, { headers });
  }

  // // obtiene lista usuarios
  // getUserList() {
  //   console.log('GetUserList---');
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/GetUserList`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<any>(URL,{ headers });
  // }

  getUserList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/ConfigParams/GetUserList`;

    return this.http.get<any>(url, { headers });
  }

  // // envia la info del gestor para actualizarla
  // updateManager( managerInEdition: any, UpdateManagerForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   console.log("updateCompanyService");
  //   const UpdateManager: any = {
  //     Id: UpdateManagerForm.controls['managerSelected'].value,
  //     ManagerName: UpdateManagerForm.controls['managerName'].value,
  //     Email: UpdateManagerForm.controls['managerEmail'].value,
  //     Password: UpdateManagerForm.controls['managerPassword'].value,
  //     Host: UpdateManagerForm.controls['managerHost'].value,
  //     Port: UpdateManagerForm.controls['managerPort'].value,
  //     SSL: UpdateManagerForm.controls['managerSSL'].value,
  //     Active: UpdateManagerForm.controls['managerStatus'].value
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<any>(`${AppConstants.API_URL_INTEREST_RATE}api/Manager/UpdateManager`,
  //   UpdateManager,
  //     { headers }
  //   );
  // }

  updateManager(managerInEdition: any, UpdateManagerForm: FormGroup): Observable<any> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const UpdateManager: any = {
      Id: UpdateManagerForm.controls['managerSelected'].value,
      ManagerName: UpdateManagerForm.controls['managerName'].value,
      Email: UpdateManagerForm.controls['managerEmail'].value,
      Password: UpdateManagerForm.controls['managerPassword'].value,
      Host: UpdateManagerForm.controls['managerHost'].value,
      Port: UpdateManagerForm.controls['managerPort'].value,
      SSL: UpdateManagerForm.controls['managerSSL'].value,
      Active: UpdateManagerForm.controls['managerStatus'].value
    }

    const url = `${environment.ApiUrl}api/Manager/UpdateManager`;

    return this.http.post<any>(url, UpdateManager, { headers });
  }

  //envia la info del gestor para crear
  createManager(CreateManagerForm: FormGroup): Observable<any> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const CreateManager: any = {
      Id: 0,
      ManagerName: CreateManagerForm.controls['managerName'].value,
      Email: CreateManagerForm.controls['managerEmail'].value,
      Password: CreateManagerForm.controls['managerPassword'].value,
      Host: CreateManagerForm.controls['managerHost'].value,
      Port: CreateManagerForm.controls['managerPort'].value,
      SSL: CreateManagerForm.controls['managerSSL'].value,
      Active: CreateManagerForm.controls['managerStatus'].value,
    }

    const url = `${environment.ApiUrl}api/Manager/CreateManager`;

    return this.http.post<any>(url, CreateManager, { headers });
  }

  // // obtiene lista de companias
  // getCompanysByManager(ManagerId: number) {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Company/GetCompanyList`; //GetCompanysByManager?ManagerId=${ManagerId}
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<CompaniesResponse>(URL,{ headers });
  // }

  getCompaniesList(): Observable<CompaniesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Company/GetCompanyList`;

    return this.http.get<CompaniesResponse>(url, { headers });
  }

  // // obtiene lista de companias
  // getCompanysByManager2(ManagerId: string) {
  //   console.log('------------')
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Company/GetCompanysByManager?ManagerId=${ManagerId}`; //
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<CompaniesResponse>(URL,{ headers });
  // }

  getCompanysByManager(ManagerId: string): Observable<CompaniesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Company/GetCompanysByManager?ManagerId=${ManagerId}`;

    return this.http.get<CompaniesResponse>(url, { headers });
  }

  // // obtiene lista de companias disponibles para el gestor
  // getCompaniesAvailable() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/Company/GetAvailableCompanyList`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<CompaniesResponse>(URL,{ headers });
  // }

  getCompaniesAvailable(): Observable<CompaniesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Company/GetAvailableCompanyList`;

    return this.http.get<CompaniesResponse>(url, { headers });
  }

  // // crea una nueva asignacion de gestor con una compania
  // createManagerByCompanies(managerByCompanyForm: FormGroup, managerCompanyList: Array<any>) {
  //   const TOKEN = this.storageService.getToken();
  //   let CompaniesId: string = '';
  //   managerCompanyList.forEach(function(company,ind){
  //     CompaniesId = (ind === 0) ? String(`${company.IdCompany }`) : String(`${CompaniesId},${company.IdCompany}`);
  //   });
  //   const NewManagerByCompany: any = {
  //     IdManager: managerByCompanyForm.controls['selectedManager'].value,
  //     CompaniesId: CompaniesId
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<any>(`${AppConstants.API_URL_INTEREST_RATE}api/Manager/CreateManagerByCompanies`,
  //   NewManagerByCompany,
  //     { headers }
  //   );
  // }

  createManagerByCompanies(managerByCompanyForm: FormGroup, managerCompanyList: Array<any>): Observable<any> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    let CompaniesId: string = '';
    managerCompanyList.forEach(function(company,ind){
      CompaniesId = (ind === 0) ? String(`${company.IdCompany }`) : String(`${CompaniesId},${company.IdCompany}`);
    });
    const NewManagerByCompany: any = {
      IdManager: managerByCompanyForm.controls['selectedManager'].value,
      CompaniesId: CompaniesId
    }

    const url = `${environment.ApiUrl}api/Manager/CreateManagerByCompanies`;

    return this.http.post<any>(url, NewManagerByCompany, { headers });
  }

  // // obtiene lista de correos registrados
  // getEmailList() {
  //   const TOKEN = this.storageService.getToken();
  //   const URL = `${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/GetEmailList`;
  //   console.log(URL);
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.get<any>(URL,{ headers });
  // }

  getEmailList(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/ConfigParams/GetEmailList`;

    return this.http.get<any>(url, { headers });
  }

  // // envia la info del gestor para crear
  // createEmail(emailForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   const CreateManager: any = {
  //     Id: 0,
  //     Email: emailForm.controls['managerEmail'].value,
  //     Password: emailForm.controls['managerPassword'].value,
  //     Host: emailForm.controls['managerHost'].value,
  //     Port: emailForm.controls['managerPort'].value,
  //     SSL: emailForm.controls['managerSSL'].value,
  //     ActiveMailsService: emailForm.controls['managerStatus'].value,
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<any>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/CreateEmail`,
  //   CreateManager,
  //     { headers }
  //   );
  // }

  createEmail(emailForm: FormGroup): Observable<any> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const CreateManager: any = {
      Id: 0,
      Email: emailForm.controls['managerEmail'].value,
      Password: emailForm.controls['managerPassword'].value,
      Host: emailForm.controls['managerHost'].value,
      Port: emailForm.controls['managerPort'].value,
      SSL: emailForm.controls['managerSSL'].value,
      ActiveMailsService: emailForm.controls['managerStatus'].value,
    }

    const url = `${environment.ApiUrl}api/ConfigParams/CreateEmail`;

    return this.http.post<any>(url, CreateManager, { headers });
  }

  // // envia la info del gestor para crear
  // updateEmail(emailForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();
  //   const CreateManager: any = {
  //     Id: emailForm.controls['userSelected'].value,
  //     Email: emailForm.controls['managerEmail'].value,
  //     Password: emailForm.controls['managerPassword'].value,
  //     Host: emailForm.controls['managerHost'].value,
  //     Port: emailForm.controls['managerPort'].value,
  //     SSL: emailForm.controls['managerSSL'].value,
  //     ActiveMailsService: emailForm.controls['managerStatus'].value,
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN}`
  //   });
  //   return this.http.post<any>(`${AppConstants.API_URL_INTEREST_RATE}api/ConfigParams/UpdateEmail`,
  //   CreateManager,
  //     { headers }
  //   );
  // }

  updateEmail(emailForm: FormGroup): Observable<any> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const CreateManager: any = {
      Id: emailForm.controls['userSelected'].value,
      Email: emailForm.controls['managerEmail'].value,
      Password: emailForm.controls['managerPassword'].value,
      Host: emailForm.controls['managerHost'].value,
      Port: emailForm.controls['managerPort'].value,
      SSL: emailForm.controls['managerSSL'].value,
      ActiveMailsService: emailForm.controls['managerStatus'].value,
    }

    const url = `${environment.ApiUrl}api/ConfigParams/UpdateEmail`;

    return this.http.post<any>(url, CreateManager, { headers });
  }
  //#endregion PROVEEDOR

  downloadReportFile(FileName: string) {
    const headers = new HttpHeaders({
      'responseType': 'blob',
      'Content-Type': 'application/json'
    });
    const url = `${environment.ApiUrl}api/ConfigParams/DownloadReportFile?ReportName=${FileName}`;
    return this.http.get<FileResponse>(url, { headers });
  }
}
