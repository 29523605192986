import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import {
  CompanyListResponse, CompanyModel, AlertListResponse, IAlert, AlertToCompanyModelResponse,
  CompanyAlertListResponse, CompanyAlertList

} from './../../../../models/index'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-assing',
  templateUrl: './assing.component.html',
  styleUrls: ['./assing.component.css']
})
export class AssignAlertToCompanyComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public createAssingForm: FormGroup;

  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService) { }

  errorMessage: string; // variable para el mensaje en caso de error
  companyList: Array<CompanyModel>; // obj almacena la lista de companias activas
  alertList: Array<IAlert>; // obj almacena la lista de alertas activas
  companyAlertList: Array<CompanyAlertList>; // obj almacena lista de Compania+Alertas
  ngOnInit() {
    this.ngInLoad();
  }

  ngInLoad() {
    this.createAssingForm = this.fb.group({
      companyId: ['', Validators.required],
      alertId: ['', Validators.required]
    });

    this.getCompanies();

  }

  get formCreateAssing() {
    return this.createAssingForm.controls;
  }

  getCompanies() {
    this.blockUI.start('Buscando compañías');
    console.log('Buscando compañías');
    this.configParamsService.getCompanyList()
      .subscribe((data: CompanyListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.companyList = data.CompanyList;
          this.blockUI.stop();
          this.getAlerts();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  getAlerts() {
    this.blockUI.start('Buscando alertas');
    console.log('Buscando alertas');
    this.configParamsService.getAlertList()
      .subscribe((data: AlertListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertList = data.AlertList;
          this.blockUI.stop();
          this.getCompanyAlerts();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  getCompanyAlerts() {
    this.blockUI.start('Buscando alertas por compañía');
    console.log('Buscando alertas');
    this.configParamsService.getCompanyAlertList()
      .subscribe((data: CompanyAlertListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.companyAlertList = data.CompanyAlertList;
          console.log(this.companyAlertList);
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  createAlert() {
    this.blockUI.start('Creando asignación de la alerta');
    console.log('Creando asignación de la alerta');
    this.configParamsService.createAssignAlertToCompany(this.createAssingForm)
      .subscribe((data: AlertToCompanyModelResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Asignación creada correctamente`, ALERTS.success); 
          this.getCompanyAlerts();
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

}
