<block-ui>
    <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left" (navChange)="onNavChange($event)">
        <li [ngbNavItem]="1">
            <a ngbNavLink><b>Asignación de roles</b></a>
            <ng-template ngbNavContent>

                <div class="row" cdkDropListGroup>
                    <div class="col-sm-3 mt-2">
                        <h5>Usuarios</h5>
                        <ul class="list-group list-group mt-1 min-role-size">
                            <li [ngClass]="{'selectedRole' : user.IsSelected}" class="list-group-item item-hover" *ngFor="let user of users" [id]="user.Id" (click)="SelectUser(user)"> {{user.Email}} </li>
                        </ul>
                    </div>
                    <div class="col-sm-4 mt-2">
                        <h5>Roles asociados</h5>
                        <div cdkDropList [cdkDropListData]="assignedRoles" class="example-list" (cdkDropListDropped)="drop($event, false)">
                            <div class="example-box item-hover" *ngFor="let item of assignedRoles" cdkDrag>{{item.Name}}</div>
                        </div>
                    </div>
                    <div class="col-sm-1 mt-2">
                        <h6>&nbsp;</h6>
                        <button class="btn btn-sm btn-light btn-st shadow btn-block" (click)="OffsetRoles(0)"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        <br>
                        <button class="btn btn-sm btn-light btn-st shadow btn-block" (click)="OffsetRoles(1)"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-sm-4 mt-2">
                        <h5>Roles disponibles</h5>

                        <div cdkDropList [cdkDropListData]="roles" class="example-list" (cdkDropListDropped)="drop($event, false)">
                            <div class="example-box item-hover" *ngFor="let role of roles" cdkDrag>{{role.Name}}</div>
                        </div>

                    </div>
                </div>

            </ng-template>
        </li>

        <li [ngbNavItem]="2">
            <a ngbNavLink><b>Roles</b></a>
            <ng-template ngbNavContent>

                <div class="row  mt-1">
                    <div class="offset-sm-10 col-sm-2">
                        <div class="form-group">
                            <h6>&nbsp;</h6>
                            <button class="form-control form-control-sm btn-light shadow-sm btn-st float-right" (click)="RaisePermissionModal(content_permission, false, true)">
                                <i aria-hidden="true" class="fa fa-plus cursor-hand"></i> Agregar Rol</button>
                        </div>

                    </div>
                </div>



                <div class="row" cdkDropListGroup>
                    <div class="col-sm-3 mt-2">
                        <h5>Roles</h5>
                        <ul class="list-group list-group mt-1 min-role-size">
                            <li [ngClass]="{'selectedRole' : role.IsSelected}" class="list-group-item item-hover" *ngFor="let role of roles" [id]="role.Id" (click)="SelectRole(role)"> <i class="fa fa-edit" style="cursor: pointer; font-size: 15px;" title="Editar rol" (click)="GetRole(role.Id, content_permission)"></i> {{role.Name}} <i *ngIf="!role.IsActive" ngbPopover="Role inactivo" triggers="mouseenter:mouseleave"
                                    class="fa fa-circle" style="font-size: 10px; margin-bottom: 15px; color: rgba(255, 0, 0, 0.5);;" aria-hidden="true"></i></li>
                        </ul>
                    </div>
                    <div class="col-sm-4 mt-2">
                        <h5>Permisos asociados</h5>
                        <div cdkDropList [cdkDropListData]="Assignedpermissions" class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box item-hover" *ngFor="let item of Assignedpermissions" cdkDrag>{{item.Name}}</div>
                        </div>
                    </div>
                    <div class="col-sm-1 mt-2">
                        <h6>&nbsp;</h6>
                        <button class="btn btn-sm btn-light btn-st shadow btn-block" (click)="OffsetPermissions(0)"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
                        <br>
                        <button class="btn btn-sm btn-light btn-st shadow btn-block" (click)="OffsetPermissions(1)"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
                    </div>
                    <div class="col-sm-4 mt-2">
                        <h5>Permisos disponibles</h5>

                        <div cdkDropList [cdkDropListData]="permissions" class="example-list" (cdkDropListDropped)="drop($event)">
                            <div class="example-box item-hover" *ngFor="let item of permissions" cdkDrag>{{item.Name}}</div>
                        </div>

                        <!-- <ul class="list-group list-group mt-1">
                            <li class="list-group-item item-hover" *ngFor="let permission of permissions;" [id]="permission.Id">{{permission.Name}}</li>
                        </ul> -->

                        <!-- <tr *ngFor="let permission of permissions; let i = index" class="p-0">
                            <td scope="row">{{(i + 1)}}</td>
                            <td>{{permission.Name}}</td>
                            <td>{{permission.Code}}</td>
                            <td>{{GetPermissionType(permission.Type)}}</td>
                            <td class="text-center"><i class="fa fa-{{permission.IsActive ? 'check': 'times'}}"></i></td>
                            <td class="justify-content-center option clasesita">
                                <i class="fa fa-edit" style="cursor: pointer;" title="Editar permission" (click)="GetPermission(permission.Id, content_permission)"></i>
                            </td>
                        </tr> -->

                    </div>
                </div>




            </ng-template>
        </li>
        <li [ngbNavItem]="3">
            <a ngbNavLink><b>Permisos</b></a>
            <ng-template ngbNavContent>

                <div class="row  mt-1">
                    <div class="offset-sm-10 col-sm-2">
                        <div class="form-group">
                            <h6>&nbsp;</h6>
                            <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st float-right" (click)="RaisePermissionModal(content_permission)">
                                <i aria-hidden="true" class="fa fa-plus cursor-hand"></i> Agregar permiso</button>
                        </div>

                    </div>
                </div>

                <div class="row" class="tableFixHead">
                    <table class="table table-striped table-sm mt-2">
                        <thead style="background-color: #172749; color: white;">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Codigo</th>
                                <th scope="col">Tipo</th>
                                <th class="text-center" scope="col">Activo</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let permission of permissions; let i = index" class="p-0">
                                <td scope="row">{{(i + 1)}}</td>
                                <td>{{permission.Name}}</td>
                                <td>{{permission.Code}}</td>
                                <td>{{GetPermissionType(permission.Type)}}</td>
                                <td class="text-center"><i class="fa fa-{{permission.IsActive ? 'check': 'times'}}"></i>
                                </td>
                                <td class="justify-content-center option clasesita">
                                    <i class="fa fa-edit" style="cursor: pointer;" title="Editar permiso" (click)="GetPermission(permission.Id, content_permission)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>



    <ng-template #content_permission let-modal>
        <div class="modal-header">
            <h5 class="modal-title">{{ isPermissionNavActive ? 'Permiso': 'Rol' }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="permissionForm" *ngIf="isPermissionNavActive">
                <div class="form-group" style="display: none;">
                    <label for="">Id</label>
                    <input type="number" class="form-control form-control-sm" aria-describedby="" formControlName="Id">
                    <!-- <small class="form-text text-muted text-danger">We'll never share your email with anyone else.</small> -->
                </div>
                <div class="form-group mRequired">
                    <label for="">Nombre</label>
                    <input type="text" class="form-control form-control-sm" aria-describedby="" formControlName="Name">
                </div>
                <div class="form-group mRequired">
                    <label for="">Codigo</label>
                    <input *ngIf="permissionForm.get('Id').value == -1" type="text" class="form-control form-control-sm" aria-describedby="" formControlName="Code">
                    <input *ngIf="permissionForm.get('Id').value != -1" readonly type="text" class="form-control form-control-sm" aria-describedby="" formControlName="Code">
                </div>
                <div class="form-group mRequired">
                    <label for="">Tipo</label>
                    <select class="form-control form-control-sm" formControlName="Type">
                        <option value="0">Lectura</option>
                        <option value="1">Modificación</option>
                    </select>
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="IsActive" style="transform: scale(1.5);">
                    <label class="form-check-label" for="exampleCheck1">Activo</label>
                </div>
            </form>

            <form [formGroup]="roleForm" *ngIf="isRoleNavActive">
                <div class="form-group" style="display: none;">
                    <label for="">Id</label>
                    <input type="number" class="form-control form-control-sm" aria-describedby="" formControlName="Id">
                    <!-- <small class="form-text text-muted text-danger">We'll never share your email with anyone else.</small> -->
                </div>
                <div class="form-group mRequired">
                    <label for="">Nombre</label>
                    <input *ngIf="roleForm.get('Id').value == -1" type="text" class="form-control form-control-sm" aria-describedby="" formControlName="Name">
                    <input *ngIf="roleForm.get('Id').value != -1" type="text" class="form-control form-control-sm" readonly aria-describedby="" formControlName="Name">
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="IsActive" style="transform: scale(1.5);">
                    <label class="form-check-label" for="exampleCheck1">Activo</label>
                </div>
            </form>
            <br>
            <div class="row">
                <div class="col-sm-4 offset-sm-4">

                    <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st float-right" (click)="Store()"><i class="fa fa-floppy-o" aria-hidden="true"></i> Guardar</button>
                </div>
                <div class="col-sm-4">
                    <button type="button" class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="ClosePermissionModal()"><i class="fa fa-times-circle" aria-hidden="true"></i>
                        Cerrar&nbsp;</button>

                </div>
            </div>
        </div>
    </ng-template>

</block-ui>
