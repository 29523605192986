<!-- *ngIf="!deviceDetector.isMobile() || (deviceDetector.isMobile() && !sidebarOpened)" *ngIf="!deviceDetector.isMobile()"-->
<header class="row header sticky-top m-0 shadow-sm" *ngIf="currentUser">
  <div class="col-md-4">
    <div class="row pl-3">
      <button
        type="button"
        [title]="sidebarState ? 'Cerrar menú' : 'Abrir menú'"
        class="btn mx-1 my-2"
        (click)="toggleSidebar()"
      >
        <i
          [ngClass]="{
            'fa-bars': !sidebarState,
            'fa-chevron-left': sidebarState
          }"
          class="fa fa-lg"
          aria-hidden="true"
        ></i>
      </button>
      <div class="pt-1">
        <h4 class="mt-2">{{ currentPath }}</h4>
      </div>
    </div>
  </div>
  <div class="col-md-8 text-right">
    <i
      class="fa fa-2x fa-user-circle-o mt-2"
      aria-hidden="true"
      [title]="sessionService.session.UserName"
    ></i>
  </div>
</header>
