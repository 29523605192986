import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QuickAccessService {
  constructor() {}

  getQuickAccess() {
    let accessList = localStorage.getItem('AccessList');
    if (!accessList) return [];

    return JSON.parse(accessList);
  }

  setQuickAccessList(accessList: any[]) {
    localStorage.removeItem('AccessList');

    localStorage.setItem('AccessList', JSON.stringify(accessList));
  }
}
