import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import {
  CompanyModelResponse, CompanyListResponse, CompanyModel, SapConnectionModel, SapConnResponse
} from './../../../../models/index'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-manager-by-company',
  templateUrl: './manager-by-company.component.html',
  styleUrls: ['./manager-by-company.component.css']
})
export class ManagerByCompanyComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  public managerByCompanyForm: FormGroup;
  public updateManagerForm: FormGroup;

  managerList: Array<any>; // obj almacena la lista de gestores
  managerInEdition: any; // obj almacena el gestor seleccionado    
  managerCompanyList: Array<any> = []; // obj almacena la lista de companias del gestor
  availabeCompanyList: Array<any> = []; // obj almacena la lista de companias disponibles
  CompaniesIdList: Array<any> = []; // obj almacena los id de companias para crear la asignacion a un gestor

  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService
  ) { }

  errorMessage: string; // variable para el mensaje en caso de error

  ngOnInit() {
    this.ngOnLoad();
  }

  ngOnLoad() {
    this.managerByCompanyForm = this.fb.group({
      selectedManager: ['', Validators.required]
    });

    this.getManagerList();    
  }

  get getManagerByCompanyForm() {
    return this.managerByCompanyForm.controls;
  }
  get updateFormManager() {
    return this.updateManagerForm.controls;
  }

  getManagerList() {
    this.blockUI.start('Buscando gestores');
    console.log('Buscando gestores');
    this.configParamsService.getManagerList()
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.managerList = data.ManagerList;
          this.blockUI.stop();                   
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.error);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getCompaniesAvailable() {
    this.blockUI.start('Buscando compañías disponibles');
    console.log('Buscando compañías disponibles');
    this.configParamsService.getCompaniesAvailable()
      .subscribe((data: CompanyListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.availabeCompanyList = data.CompanyList;
          this.blockUI.stop();
         } 
        //  else {
        //   this.blockUI.stop(); // Stop blocking
        //   this.errorMessage = data.errorInfo.Message;
        //   this.alertService.warningAlert(`${data.errorInfo.Message}`);
        // }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getCompanysByManager($event) {
    this.managerCompanyList = [];    
    if($event.target.value != '' || $event.target.value > 0){
      this.getCompaniesAvailable();
      this.blockUI.start('Buscando compañías del gestor');
      console.log('Buscando compañías gestor');
      this.configParamsService.getCompanysByManager($event.target.value)
        .subscribe((data: any) => {
          console.log(data);
          console.log(data.Result);
          if (data.Result) {
            this.managerCompanyList = data.ManagerByCompanyList;
            
            this.blockUI.stop();
          } else {
            this.blockUI.stop(); // Stop blocking
            this.errorMessage = data.errorInfo.Message;
            this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
          }
        }, (error: any) => {
          console.log(error);
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
        });
    }else{
      this.availabeCompanyList = [];
    }    
  }

  createManagerByCompanyForm() {
    this.blockUI.start('Creando asignación, un momento por favor.');
    console.log('Creando asignacion');
    this.configParamsService.createManagerByCompanies(this.managerByCompanyForm, this.managerCompanyList)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Operación exitosa`, ALERTS.success);
          this.managerByCompanyForm.reset(); 
          this.getManagerList();
          this.managerCompanyList = [];  
          this.availabeCompanyList = [];        
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  updateManager() {
    this.blockUI.start('Acutalizando el gestor, un momento por favor.');
    console.log('Acutalizando el gestor');
    this.configParamsService.updateManager(this.managerInEdition, this.updateManagerForm)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Gestor actualizado correctamente`, ALERTS.success);
          this.updateManagerForm.reset(); 
          this.getManagerList();         
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  managerSelected($event) {
    console.log("CompanySelected");    
    this.managerInEdition = undefined;
    this.managerInEdition = this.managerList.find(manager => manager.Id == $event.target.value);
    if (this.managerInEdition) {
      this.PatchAlertData(this.managerInEdition);
    } else {
      this.updateManagerForm.reset();
    }
  }

  PatchAlertData(managerInEdition: any){ 
    console.log("CompanyPatch");   
    this.updateManagerForm.reset({
      managerSelected: managerInEdition.Id,
      managerName: managerInEdition.ManagerName,
      managerEmail: managerInEdition.EmailId,
      managerStatus: managerInEdition.Status,
      
    })
  }

  removeCompanyFromManagerCompanyList(Company: any) {
    let index = this.managerCompanyList.findIndex(comp => comp.IdCompany === Company.IdCompany);
    this.managerCompanyList.splice(index, 1);
    this.availabeCompanyList.push(Company);    
  }

  pushCompanyToManagerCompanyList(Company: any) {
    let index = this.availabeCompanyList.findIndex(comp => comp.IdCompany === Company.IdCompany);
    this.availabeCompanyList.splice(index, 1);
    this.managerCompanyList.push(Company);
  }

}
