import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from '../common/angular.index';

@Injectable({
  providedIn: 'root',
})
export class BlockUIHandlerService {
  @BlockUI() blockUI: NgBlockUI;

  private totalTasks: number;
  finishedTasks: number;

  constructor() {}

  private intervalTasksState() {
    const interval = setInterval(() => {
      if (this.totalTasks === this.finishedTasks) {
        this.blockUI.stop();
        clearInterval(interval);
      }
    }, 200);
  }

  Start(totalTasks: number, _message?: string) {
    this.blockUI.start(_message);
    this.totalTasks = totalTasks;
    this.finishedTasks = 0;
    this.intervalTasksState();
  }

  Stop() {
    this.finishedTasks = this.totalTasks;
  }

  taskFinished() {
    this.finishedTasks++;
  }
}
