import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import {
  Observable,
  distinctUntilChanged,
  debounceTime,
  filter,
  map,
  FormBuilder,
  FormGroup,
  Validators,
  NgbModal,
} from '../../common/angular.index';
import {
  ALERTS,
  Contact,
  Email,
  Parameter,
  ReportParameter,
} from 'src/app/common/models.index';
// services
import {
  AlertService,
  BlockUIHandlerService,
  CommonService,
  ContactsService,
  RptManagerService,
} from 'src/app/common/services.index';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css'],
})
export class PrintReportComponent implements OnInit, OnDestroy {
  reportId: number;
  routerEventsSubs: Subscription;

  parameters: ReportParameter[];
  parameterSubgroup1: ReportParameter[];
  parameterSubgroup2: ReportParameter[];
  parameterSubgroup3: ReportParameter[];
  parameterSubgroup4: ReportParameter[];
  paramaterListGroup: ReportParameter[][];

  parametersForm: FormGroup;
  emailForm: FormGroup;
  emailRecipients: string[];
  reportKeys: Parameter[];
  contacts: Contact[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private rptManagerService: RptManagerService,
    private alertService: AlertService,
    private commonService: CommonService,
    private blockUI: BlockUIHandlerService,
    private contactsService: ContactsService
  ) {}

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
      Subject: ['', Validators.required],
      Body: ['', Validators.required],
      Recipient: [''],
    });
    this.initApp();
    this.routerEventsSubs = this.router.events
      .pipe(filter((event$) => event$ instanceof NavigationEnd))
      .subscribe(() => this.initApp());
  }

  ngOnDestroy() {
    this.routerEventsSubs.unsubscribe();
  }

  resetEmailForm() {
    this.emailForm.reset({
      Subject: '',
      Body: '',
      Recipient: '',
    });
  }

  resetParameterForm() {
    for (const control in this.parametersForm.controls) {
      this.parametersForm.get(control).reset();
    }
  }

  initApp() {
    this.reportId = Number(this.route.snapshot.paramMap.get('reportId'));
    this.parametersForm = this.formBuilder.group({});
    this.resetEmailForm();
    this.emailRecipients = [];
    this.parameters = [];
    this.reportKeys = [];
    this.parameterSubgroup1 = [];
    this.parameterSubgroup2 = [];
    this.parameterSubgroup3 = [];
    this.parameterSubgroup4 = [];
    this.paramaterListGroup = [];
    this.paramaterListGroup.push(
      this.parameterSubgroup1,
      this.parameterSubgroup2,
      this.parameterSubgroup3,
      this.parameterSubgroup4
    );
    this.blockUI.Start(2);
    this.getContacts();
    this.getParameters();
  }

  getParameters() {
    this.rptManagerService
      .GetParameters(this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.finishedTasks++;
          if (response.Result) {
            this.parameters = response.Parameters;

            response.Parameters.forEach((x) => {
              switch (x.GridCol) {
                case 0:
                  this.parameterSubgroup1.push(x);
                  break;
                case 1:
                  this.parameterSubgroup2.push(x);
                  break;
                case 2:
                  this.parameterSubgroup3.push(x);
                  break;
                case 3:
                  this.parameterSubgroup4.push(x);
                  break;
              }
            });

            this.setFormControls();
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          this.blockUI.finishedTasks++;
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  getContacts() {
    this.contacts = [];
    this.contactsService.getContacts().subscribe(
      (response) => {
        this.blockUI.finishedTasks++;
        if (response.Result) {
          this.contacts = response.Contacts;
        } else
          this.alertService.ShowBigCenteredAlert(
            response.ErrorInfo.Message,
            ALERTS.info
          );
      },
      (err) => {
        this.blockUI.finishedTasks++;
        this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
      }
    );
  }

  searchContact = ($text: Observable<string>) =>
    $text.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) =>
        !term || term.length === 0
          ? this.contacts.map((x) => `${x.Email}`).slice(0, 10)
          : this.contacts
              .filter(
                (x) =>
                  x.Email.toUpperCase().search(term.toUpperCase()) > -1 ||
                  x.Name.toUpperCase().search(term.toUpperCase()) > -1
              )
              .map((x) => `${x.Email}`)
              .slice(0, 10)
      )
    );

  onContactSelected(event$) {
    event$.preventDefault();
    this.addRecipient(event$.item);
  }

  addRecipient(recipient: string) {
    if (!recipient) return;
    if (!this.emailRecipients.some((x) => x === recipient))
      this.emailRecipients.push(recipient);
    this.emailForm.get('Recipient').reset();
  }

  onClickRemoveRecipient(index: number) {
    this.emailRecipients.splice(index, 1);
  }

  onClickPrintReport() {
    this.blockUI.Start(1);
    const parameters: Parameter[] = this.getParametersForReportPrint();
    this.rptManagerService
      .PrintReport(parameters, this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.Stop();

          if (response.Result) {
            this.commonService.downloadFile(
              response.Print,
              'Reporte',
              'application/pdf',
              'pdf'
            );
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          this.blockUI.Stop();
          console.log(err);
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  onClickSendEmail() {
    this.blockUI.Start(1);
    let email: Email = {
      Body: this.emailForm.get('Body').value,
      Recipients: this.emailRecipients,
      Subject: this.emailForm.get('Subject').value,
      Parameters: this.getParametersForReportPrint(),
    };

    this.rptManagerService
      .SendEmail(email, this.reportId)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.Stop();

          if (response.Result) {
            this.alertService.ShowSmallCornerAlert(
              'Correo enviado exitosamente',
              ALERTS.success
            );

            this.resetEmailForm();
            this.emailRecipients = [];
            this.dismissModal(true);
          } else {
            this.alertService.ShowBigCenteredAlert(
              response.ErrorInfo.Message,
              ALERTS.error
            );
          }
        },
        (err) => {
          this.blockUI.Stop();
          this.alertService.ShowBigCenteredAlert(err, ALERTS.error);
        }
      );
  }

  getParametersForReportPrint() {
    let parameters: Parameter[] = [];
    if (this.parameters) {
      this.parameters.forEach((x) => {
        let parameter: Parameter = {
          Key: x.Name,
          Type: x.Type,
          Value: this.parametersForm.get(x.Name).value,
        };

        parameters.push(parameter);
      });
    }

    return parameters;
  }

  setFormControls() {
    this.parameters.forEach((x) => {
      this.parametersForm.addControl(
        x.Name,
        !x.Required
          ? new FormControl('')
          : new FormControl('', {
              validators: Validators.required,
            })
      );
    });
  }

  onClickSendMailModal(modalSendMail: any) {
    this.modalService.open(modalSendMail, {
      backdrop: true,
      scrollable: true,
      size: 'lg',
    });
  }

  dismissModal(result: boolean) {
    this.modalService.dismissAll(result);
  }

  get emailFormControls() {
    return this.emailForm.controls;
  }
}
