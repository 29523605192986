<div class="margin-bottom">
    <div class="header text-right mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Mantenimiento de gestores</h3>
        </div>
    </div>
</div>
<div class="mt-2">
    <ngb-tabset class="pt-2" [destroyOnHide]="false">
        <ngb-tab title="Actualizar gestor">
            <ng-template ngbTabContent>
                <div class="row justify-content-md-center pt-3">
                    <div class="col-sm-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="updateManagerForm">
                                    <div class="row row-space">
                                        <div class="col-md-4">
                                            <label for="id">Gestor</label>
                                            <div>
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="managerSelected" (change)="managerSelected($event)"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerSelected.errors && (updateFormManager.managerSelected.touched || updateFormManager.managerSelected.untouched) }">
                                                    <option value="">--Gestores--</option>
                                                    <option *ngFor="let manager of managerList" [value]="manager.Id">
                                                        {{manager.ManagerName}}
                                                    </option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerSelected.errors">
                                                    <sup>*</sup>Por favor seleccione un gestor
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="id">Nombre del gestor</label>
                                            <input type="text" name="managerName"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="managerName" formControlName="managerName"
                                                [ngClass]="{ 'is-invalid': updateFormManager.managerName.errors && (updateFormManager.managerName.touched || updateFormManager.managerName.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="updateFormManager.managerName.errors">
                                                <sup>*</sup>Por favor digite el nombre del gestor
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <label for="id">Correo electrónico</label>
                                            <div>
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="managerEmail"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerEmail.errors && (updateFormManager.managerEmail.touched || updateFormManager.managerEmail.untouched) }">
                                                    <option value="">--Email--</option>
                                                    <option *ngFor="let emailManager of emailManagerList" [value]="emailManager.Id">
                                                        {{emailManager.Email}}
                                                    </option>
                                                </select>
                                                 error block
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor seleccione un correo electrónico
                                                </div>
                                            </div>
                                        </div>    -->
                                        <!-- <div class="col-md-3">
                                            <label for="id">Correo electrónico</label>
                                            <input type="text" name="managerEmail"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="managerEmail" formControlName="managerEmail"
                                                [ngClass]="{ 'is-invalid': updateFormManager.managerEmail.errors && (updateFormManager.managerEmail.touched || updateFormManager.managerEmail.untouched) }">
                                            <div class="invalid-feedback" *ngIf="updateFormManager.managerEmail.errors">
                                                <sup>*</sup>Por favor digite el correo electrónico
                                            </div>
                                        </div> -->
                                        <div class="col-md-4">
                                            <label for="id">Estado</label>
                                            <select
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                formControlName="managerStatus"
                                                [ngClass]="{ 'is-invalid': updateFormManager.managerStatus.errors && (updateFormManager.managerStatus.touched || updateFormManager.managerStatus.untouched) }">
                                                <option value="">Seleccione un estado</option>
                                                <option value="true">Activo</option>
                                                <option value="false">Inactivo</option>
                                            </select>
                                            <!-- error block -->
                                            <div class="invalid-feedback"
                                                *ngIf="updateFormManager.managerStatus.errors">
                                                <sup>*</sup>Por favor seleccione un estado
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space pt-2">
                                        <div class="col-md-3">
                                            <label for="id">Correo electrónico</label>
                                            <div>
                                                <input type="text" name="managerEmail"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerEmail" formControlName="managerEmail"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerEmail.errors && (updateFormManager.managerEmail.touched || updateFormManager.managerEmail.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor digite un correo electrónico
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <label for="id">Contraseña</label>
                                            <div>
                                                <input type="password" name="managerPassword"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPassword" formControlName="managerPassword"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerPassword.errors && (updateFormManager.managerPassword.touched || updateFormManager.managerPassword.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor digite la contraseña
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Host</label>
                                            <div>
                                                <input type="text" name="managerHost"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerHost" formControlName="managerHost"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerHost.errors && (updateFormManager.managerHost.touched || updateFormManager.managerHost.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerHost.errors">
                                                    <sup>*</sup>Por favor digite un host
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Puerto</label>
                                            <div>
                                                <input type="text" name="managerPort"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPort" formControlName="managerPort"
                                                    [ngClass]="{ 'is-invalid': updateFormManager.managerPort.errors && (updateFormManager.managerPort.touched || updateFormManager.managerPort.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="updateFormManager.managerPort.errors">
                                                    <sup>*</sup>Por favor digite un puerto
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id" class="mb-0">SSL</label>
                                            <div class="mt-0">
                                                <label class="switch">
                                                    <input type="checkbox" formControlName="managerSSL">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-3 pt-2">
                                            <button class="form-control" (click)="updateManager()"
                                                [disabled]="!updateManagerForm.valid">
                                                <i class="fa fa-refresh"></i>&nbsp;Actualizar gestor</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Crear gestor">
            <ng-template ngbTabContent>
                <div class="row justify-content-md-center pt-3">
                    <div class="col-sm-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="createManagerForm">
                                    <div class="row row-space">
                                        <!-- <div class="col-md-4">
                                            <label for="id">Usuarios</label>
                                            <div>
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="userSelected"
                                                    [ngClass]="{ 'is-invalid': createFormManager.userSelected.errors && (createFormManager.userSelected.touched || createFormManager.userSelected.untouched) }">
                                                    <option value="">--Usuario--</option>
                                                    <option *ngFor="let user of userList" [value]="user.UserId">
                                                        {{user.UserEmail}}
                                                    </option>
                                                </select>
                                                error block -->
                                                <!-- <div class="invalid-feedback"
                                                    *ngIf="createFormManager.userSelected.errors">
                                                    <sup>*</sup>Por favor seleccione un usuario
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-4">
                                            <label for="id">Nombre del gestor</label>
                                            <input type="text" name="managerName"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="managerName" formControlName="managerName"
                                                [ngClass]="{ 'is-invalid': createFormManager.managerName.errors && (createFormManager.managerName.touched || createFormManager.managerName.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="createFormManager.managerName.errors">
                                                <sup>*</sup>Por favor digite el nombre del gestor
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="id">Correo electrónico</label>
                                            <div>
                                                <input type="text" name="managerEmail"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerEmail" formControlName="managerEmail"
                                                    [ngClass]="{ 'is-invalid': createFormManager.managerEmail.errors && (createFormManager.managerEmail.touched || createFormManager.managerEmail.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="createFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor digite un correo electrónico
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="id">Contraseña</label>
                                            <div>
                                                <input type="password" name="managerPassword"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPassword" formControlName="managerPassword"
                                                    [ngClass]="{ 'is-invalid': createFormManager.managerPassword.errors && (createFormManager.managerPassword.touched || createFormManager.managerPassword.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="createFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor digite la contraseña
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space pt-2">
                                        <!-- <div class="col-md-3">
                                            <label for="id">Correo electrónico</label>
                                            <div>
                                                <input type="text" name="managerEmail"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerEmail" formControlName="managerEmail"
                                                    [ngClass]="{ 'is-invalid': createFormManager.managerEmail.errors && (createFormManager.managerEmail.touched || createFormManager.managerEmail.untouched) }">
                                                 error block
                                                <div class="invalid-feedback"
                                                    *ngIf="createFormManager.managerEmail.errors">
                                                    <sup>*</sup>Por favor digite un correo electrónico
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-3">
                                            <label for="id">Estado</label>
                                            <select
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                formControlName="managerStatus"
                                                [ngClass]="{ 'is-invalid': createFormManager.managerStatus.errors && (createFormManager.managerStatus.touched || createFormManager.managerStatus.untouched) }">
                                                <option value="">Seleccione un estado</option>
                                                <option value="true">Activo</option>
                                                <option value="false">Inactivo</option>
                                            </select>
                                            <!-- error block -->
                                            <div class="invalid-feedback"
                                                *ngIf="createFormManager.managerStatus.errors">
                                                <sup>*</sup>Por favor seleccione un estado
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Host</label>
                                            <div>
                                                <input type="text" name="managerHost"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerHost" formControlName="managerHost"
                                                    [ngClass]="{ 'is-invalid': createFormManager.managerHost.errors && (createFormManager.managerHost.touched || createFormManager.managerHost.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="createFormManager.managerHost.errors">
                                                    <sup>*</sup>Por favor digite un host
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id">Puerto</label>
                                            <div>
                                                <input type="text" name="managerPort"
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    id="managerPort" formControlName="managerPort"
                                                    [ngClass]="{ 'is-invalid': createFormManager.managerPort.errors && (createFormManager.managerPort.touched || createFormManager.managerPort.untouched) }">
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="createFormManager.managerPort.errors">
                                                    <sup>*</sup>Por favor digite un puerto
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label for="id" class="mb-0">SSL</label>
                                            <div>
                                                <label class="switch">
                                                    <input type="checkbox" formControlName="managerSSL">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space pt-2">
                                        <div class="col-md-3">
                                            <button class="form-control" (click)="createManager()"
                                                [disabled]="!createManagerForm.valid">
                                                <i class="fa fa-plus"></i>&nbsp;Crear gestor</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>
