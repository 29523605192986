export class RequirementValidation {
  public Id: number;
  public RequirementName: string;
  public RequirementId: number;
  public DocumentUpdateId: number;
  public Checked: boolean;
  public LoadSons?: boolean;
  public ParentRequirementId?: number;
  public ParentRequirementName?: string;

  constructor(
    RequirementName: string,
    RequirementId: number,
    ParentName?: string,
    ParentId?: number
  ) {
    this.Id = 0;
    this.RequirementName = RequirementName;
    this.RequirementId = RequirementId;
    this.DocumentUpdateId = 0;
    this.Checked = false;
    this.LoadSons = false;
    this.ParentRequirementId = ParentId;
    this.ParentRequirementName = ParentName;
  }
}
