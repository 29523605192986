import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';

// Models
import { AppConstants, BaseResponse, Document2 } from '../common/models.index';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentUpdateService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  postDocumentsUpdate(docsToUpdate: Document2[], documentsAge: Date) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.post<BaseResponse>(
      `${environment.ApiUrl}api/DocumentUpdate/PostDocumentsUpdate?userId=${this.sessionService.session.UserName}&documentsAge=${documentsAge}`,
      docsToUpdate,
      { headers }
    );
  }
}
