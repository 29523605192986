<block-ui>
  <div class="row">
    <div class="col-sm-8">
      <form [formGroup]="searchForm">
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Desde</label>
            <input type="date" formControlName="From" class="form-control form-control-sm" />
          </div>

          <div class="form-group col-md-3">
            <label>Hasta</label>
            <input type="date" formControlName="To" class="form-control form-control-sm" />
          </div>
          <div class="form-group col-md-3">
            <label for="">Usuario</label>
            <select formControlName="UserId" class="form-control form-control-sm">
              <option class="form-control" value="-1">Todos los usarios</option>
              <option *ngFor="let user of users" class="form-control" value="{{user.Id}}">{{user.Email}}
              </option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="">&nbsp;</label>
            <div class="form-check">
              <input class="form-check-input big-checkbox" type="checkbox" value="" formControlName="FileStatus">
              <label class="form-check-label" for="">
                &nbsp;Cancelados
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-5  offset-sm-2">
          <label>&nbsp;</label>
          <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="GetFiles()"><i
              class="fa fa-search"></i>
            Consultar</button>
        </div>
        <div class="col-sm-5">
          <label>&nbsp;</label>
          <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="UploadDocuments()"><i
              class="fa fa-upload" aria-hidden="true"></i>
            Guardar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" [ngClass]="{'tableFixHead-expanded ': isHiddenFileSelector, 'tableFixHead':!isHiddenFileSelector }" >
    <table class="table table-striped table-sm">
      <thead style="background-color: #172749; color: white;">
        <tr>
          <th scope="col" style="position: sticky; top: 0;">#</th>
          <th scope="col" style="position: sticky; top: 0;">Usuario</th>
          <th scope="col" style="position: sticky; top: 0;">Fecha</th>
          <th scope="col" style="position: sticky; top: 0;">Archivo</th>
          <th scope="col" style="position: sticky; top: 0;">Comentario</th>
          <th scope="col" style="position: sticky; top: 0;">Tipo</th>
          <th scope="col" style="position: sticky; top: 0;">Estado</th>
          <th scope="col" style="position: sticky; top: 0;">Opciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rawFile of GetFilteredFiles(); let i = index">
          <td>{{i + 1}}</td>
          <td>{{GetEmail(rawFile.UserId)}}</td>
          <td>{{rawFile.CreationDate | date:'medium'}}</td>
          <td>{{rawFile.FriendlyName}}</td>
          <td>{{MappedCommentary(rawFile.Comment)}} <span class="commentaryDots" *ngIf="MappedCommentary(rawFile.Comment).length == 20" style="cursor: pointer; font-weight: bold;" (click)="PreviewCommentary(rawFile, content_commentary)">...</span> </td>
          <td>{{rawFile.DocumentType}}</td>
          <td>{{GetFileStatus(rawFile.Status)}}</td>
          <td>
            <ul class="list-inline">
              <li class="list-inline-item cursor-hand" *ngIf="rawFile.DocumentType === 'text/xml'"
                (click)="PreviewXML(rawFile.Id, content_lists)">
                <i class="fa fa-info-circle" aria-hidden="true" title="Previsualizar lista de materiales"></i>
              </li>
              <li class="list-inline-item cursor-hand" *ngIf="rawFile.DocumentType === 'text/xml'"
                (click)="ProcessFile(rawFile.Id)">
                <i class="fa fa-check" aria-hidden="true" title="Procesar en sap"></i>
              </li>
              <li class="list-inline-item cursor-hand">
                <i class="fa fa-download" aria-hidden="true" title="Descargar documento"
                  (click)="DownloadDocument(rawFile)"></i>
              </li>
              <li class="list-inline-item cursor-hand" (click)="RaiseFileModal(content, rawFile)">
                <i class="fa fa-ban" aria-hidden="true" [openDelay]="800" ngbPopover="El archivo no será procesado"
                  triggers="mouseenter:mouseleave" popoverTitle="Cancelar documento"></i>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row" [ngClass]="{'hideFileSelector': isHiddenFileSelector, 'showFileSelector':!isHiddenFileSelector }">
    <i class="fa fa-minus float-right ml-auto mr-2 btn btn-sm" aria-hidden="true" title="Minimizar" *ngIf="!isHiddenFileSelector" (click)="MinimizeFileSelctor()"></i>
    <div class="col-sm-12">
      <div class="mcontainer cursor-hand py-auto" appDnd (fileDropped)="OnFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" class="cursor-hand" multiple
          (change)="FileBrowseHandler($event.target.files)" style="opacity: 0;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;" />
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="38" viewBox="0 0 63 64">
          <g fill="#3B454F" fill-rule="nonzero">
            <path
              d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
            <path
              d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
            <path
              d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
          </g>
        </svg>

        <h3>Arrastrar y soltar o dar click</h3>
      </div>
    </div>
  </div>
  <br>
  <div class="row mt-1" [ngClass]="{'hideFileSelector': isHiddenFileSelector, 'showFileSelector':!isHiddenFileSelector }">
    <div class="col-sm-12">
      <div class="files-list" style="height: 15vh; overflow-y: auto;">
        <div class="single-file" *ngFor="let file of files; let i = index">
          <div class="info">
            <app-progress [progress]="file?.progress" [Name]="file?.name" [weight]="file?.size" [weight_dec]="0"></app-progress>
          </div>

          <select class="form-control-sm float-right btn-st mr-2 ml-1" *ngIf="file.type == 'text/xml'"
            style="padding: 0px; border:0px; outline:0px; cursor: pointer;" id="fileWhs_{{i}}">
            <option *ngFor="let warehouse of warehouses" value="{{warehouse.WhsCode}}">{{warehouse.WhsCode}} - {{warehouse.WhsName}}</option>
          </select>

          <select class="form-control-sm float-right btn-st mr-2" *ngIf="file.type != 'text/xml'"
            style="padding: 0px; border:0px; outline:0px; cursor: pointer;" disabled id="fileWhs_{{i}}">
            <option *ngFor="let warehouse of warehouses" value="{{warehouse.WhsCode}}">{{warehouse.WhsCode}} - {{warehouse.WhsName}}</option>
          </select>
          <input id="inputCom_{{i}}" style="display: none;" />
          <button class="btn btn-sm btn-light" (click)="RaiseCustomMessage(i, content)">
            <i class="fa fa-commenting-o" aria-hidden="true"></i>
          </button>
          <button class="btn btn-sm btn-light" (click)="DeleteFile(i)">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="row fixed-row-bottom">
    <i class="fa fa-window-maximize float-right ml-auto mr-2 btn btn-sm" aria-hidden="true" *ngIf="isHiddenFileSelector" title="Maximizar" (click)="MaximizeFileSelector()"></i>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ isCancellingCommentary ? 'Cancelar archivo': 'Comentario general'}}</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="fileForm">
        <div class="form-group">
          <label for="">Comentario</label>
          <textarea ngbAutofocus rows="5" class="form-control form-control-sm" aria-describedby="emailHelp"
            formControlName="Comentary">
          </textarea>
        </div>

      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light btn-sm shadow btn-st" (click)="TriggerComentaryAction()"><i
          class="fa fa-floppy-o" aria-hidden="true"></i> Continuar</button>
      <button type="button" class="btn btn-light btn-sm shadow btn-st" (click)="CloseModal()"><i
          class="fa fa-times-circle" aria-hidden="true"></i> Cerrar&nbsp;</button>
    </div>
  </ng-template>


  <ng-template #content_commentary let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Comentario</h5>
      <button type="button" class="close btn-sm" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <h6>{{currentRawFile?.Comment}}</h6>
    </div>
  </ng-template>

  <ng-template #content_lists let-modal>
    <div class="card-header" style="background-color: transparent;">
      <h6 class="float-left" style="padding: .05rem .05rem;">Previsualización de listas</h6>
      <button type="button" class="btn btn-sm btn-st float-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <button class="btn btn-sm btn-st float-right mr-2" (click)="ToggleExpand()" title="Ocultar / Desplejar todo"
        style="background-color: transparent;">
        <i *ngIf="!isExpanded" class="fa fa-toggle-off" aria-hidden="true"></i>
        <i *ngIf="isExpanded" class="fa fa-toggle-on" aria-hidden="true"></i>
      </button>
      <select class="form-control-sm float-right btn-st mr-2"
        style="padding: 0px; border:0px; outline:0px; cursor: pointer;" id="mainWhs" [formControl]="globalWHSControl"
        (change)="UpdateGlobalWHS(1)">
        <option *ngFor="let warehouse of warehouses" value="{{warehouse.WhsCode}}"> {{warehouse.WhsCode}} - {{warehouse.WhsName}}</option>
      </select>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <div class="card mt-1" *ngFor="let product of productList">
            <div class="card-header" style="padding: 0.4rem  0.4rem; background-color: transparent;" class="shadow-sm">
              <h6 (click)="ToggleView(product)" style="cursor: pointer;" class="float-left">{{product.AccountCode}} - {{product.Name}}</h6>
            </div>
            <div class="card-body" [ngClass]="{'bodyCollpaser': product.IsHidden, 'bodyExpander':!product.IsHidden }">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="card-title">Código cuenta: {{product.AccountCode}}</h6>
                  <h6 class="card-title">Formula: {{product.Formula}}</h6>
                  <h6 class="card-title">Fecha creación formula: {{product.FormulaCreation}}</h6>
                  <h6 class="card-title">Planta: {{product.PlantName}}</h6>
                  <h6 class="card-title">Nombre especie: {{product.SpeciesName}}</h6>
                </div>
                <div class="col-sm-7">
                  <div class="tableFixHead" style="overflow-y: auto; height: 200px;">

                  <table class="table table-sm">
                    <thead>
                      <tr style="color: white;">
                        <th scope="col">#</th>
                        <th scope="col">Código cuenta</th>
                        <th scope="col">Código</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let material of product.Materials; index as i">
                        <td>{{i + 1}}</td>
                        <td>{{material.AccountCode}}</td>
                        <td>{{material.Code}}</td>
                        <td>{{material.Name}}</td>
                        <td>{{material.Quantity | number: '1.4-4'}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row" style="width: 100%;">
        <div class="col-sm">
          <input type="text" class="mr-auto form-control form-control-sm shadow" style="width: 100%;" placeholder="Comentario" [formControl]="globalCommentaryControl"/>
        </div>
        <div class="col-sm-2">
          <button type="button" class="btn btn-light btn-sm shadow btn-st btn-block" (click)="UpdateWHS()"><i class="fa fa-save"
            aria-hidden="true"></i> Guardar&nbsp;</button>
        </div>
        <div class="col-sm-2">
          <button type="button" class="btn btn-light btn-sm shadow btn-st btn-block" (click)="CloseModal()"><i
            class="fa fa-times-circle" aria-hidden="true"></i> Cerrar&nbsp;</button>
        </div>
      </div>
    </div>
  </ng-template>

</block-ui>
