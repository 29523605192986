import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditDays',
})
export class CreditDaysPipe implements PipeTransform {
  transform(items: any[], ...args: unknown[]): unknown {
    if (!items || !args) return [];
    if (args[0]) return items.filter((x) => x.CreditDays && x.CreditDays > 0);
    else return items;
  }
}
