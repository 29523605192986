import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants, IPermission } from 'src/app/models';
import { BaseResponse } from 'src/app/common/models.index';
import { Observable } from 'rxjs';
import { IPermissionResponse, IPermissionsByRoleResponse, IPermissionsResponse } from 'src/app/models/responses';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private httpClient: HttpClient) { }

  StorePermission(_permission: IPermission): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/Store`;

    return this.httpClient.post<BaseResponse>(url, _permission, { headers });
  }

  Get(): Observable<IPermissionsResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/Get`;

    return this.httpClient.get<IPermissionsResponse>(url, { headers });
  }

  GetPermission(_permissionId: number): Observable<IPermissionResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/GetPermission?permissionId=${_permissionId}`;

    return this.httpClient.get<IPermissionResponse>(url, { headers });
  }

  GetPermissionsByRole(_IdRole: number): Observable<IPermissionsResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/GetByRole?id=${_IdRole}`;

    return this.httpClient.get<IPermissionsResponse>(url, { headers });
  }

  GetPermissionsByUser(_IdUser: number): Observable<IPermissionsResponse> {
    const url = `${environment.ApiUrl}api/Permission/GetByUser?id=${_IdUser}`;

    return this.httpClient.get<IPermissionsResponse>(url);
  }

  Put(_permission: IPermission): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/Put`;

    return this.httpClient.post<BaseResponse>(url, _permission, { headers });
  }

  PutByRole(_IdRole: number, _permissions: IPermission[]): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Permission/PutByRole`;

    return this.httpClient.post<BaseResponse>(url, {
      'IdRole': _IdRole,
      'Permissions': _permissions
    }, { headers });
  }
}
