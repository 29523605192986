import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// SERVICIOS
import { AuthenticationService, StorageService, AlertService, UserService, PermissionService } from '../../services/index';
import { ALERTS } from 'src/app/common/enums';
import { first } from 'rxjs/operators';
import { SideBarService } from 'src/app/common/services.index';
import { IPermission } from 'src/app/models';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isLogin: boolean;
  loading = false;
  perms: IPermission[];
  @ViewChild("userMail") userMail: ElementRef;
  constructor(private fb: FormBuilder,
    private router: Router,
    private auth: AuthenticationService,
    private permissionService: PermissionService
    , private alertService: AlertService,
    private storageService: StorageService
    , private sidebarService: SideBarService
  ) {
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.isLogin = true;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', Validators.required]
    });

    setTimeout(() => {
      this.userMail.nativeElement.focus();
    }, 0);
  }

  // convenience getter for easy access to form fields
  get fLogin() { return this.loginForm.controls; }

  // funcion para el envio del formulario, tanto de login como de signin
  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.auth.login(this.fLogin.email.value, this.fLogin.password.value)
      .pipe(first())
      .subscribe(data => {
        this.loading = false;
        this.GetUserRole('');
        this.sidebarService.toggler.next(true);
        this.router.navigateByUrl('home');
        console.clear();

        // this.auth.thirdPartyLogin(this.fLogin.email.value, this.fLogin.password.value)
        //   .pipe(first())
        //   .subscribe(data => {
        //   }, error => {
        //     this.alertService.ShowBigCenteredAlert(`Error en app de pagos: ${error}`, ALERTS.error);
        //   });
      }, error => {
        this.loading = false;
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  /*
  * Funcion para cambiar entre el formulario de login y de sigin
  */
  clickEvent(islogin: boolean) {
    this.isLogin = islogin;
    // se activa el form de login
    if (this.isLogin) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required]
      });
      // se activa el form de recuperar la contrasenna
    } else {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
      });
    }
  }

  GetUserRole(_userId: string): void {
  }

  getUserGeoConfigs() {
    let geoConfigs: any[] = [];
  }

}
