<div class="margin-bottom">
    <div class="header text-right no-pm-0 mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Asignación de alertas</h3>
        </div>
    </div>
    <div class="row justify-content-md-center pt-4">
        <div class="col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="createAssingForm">
                        <div class="row row-space">
                            <div class="col-md-6">
                                <label for="company">Compañías</label>
                                <div class="form-group">
                                    <select class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                        formControlName="companyId" id="companyId"
                                        [ngClass]="{ 'is-invalid': formCreateAssing.companyId.errors && (formCreateAssing.companyId.touched || formCreateAssing.companyId.untouched) }">
                                        <option *ngFor="let company of companyList" [value]="company.Id">
                                            {{company.CompanyName}}
                                        </option>
                                    </select>
                                    <!-- error block -->
                                    <div class="invalid-feedback" *ngIf="formCreateAssing.companyId.errors">
                                        <sup>*</sup>Por favor seleccione una compañía
                                     </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="Status">Alertas</label>
                                <div class="form-group">
                                    <select class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                        formControlName="alertId" id="alertId"
                                        [ngClass]="{ 'is-invalid': formCreateAssing.alertId.errors && (formCreateAssing.alertId.touched || formCreateAssing.alertId.untouched) }">
                                        <option *ngFor="let alert of alertList" [value]="alert.Id">
                                            {{alert.AlertName}}
                                        </option>
                                    </select>
                                    <!-- error block -->
                                    <div class="invalid-feedback" *ngIf="formCreateAssing.alertId.errors">
                                        <sup>*</sup>Por favor seleccione una alerta
                                     </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space pt-2">
                            <div class="col-md-3">
                                <button class="form-control" (click)="createAlert()"
                                [disabled]="!createAssingForm.valid">
                                        <i class="fa fa-plus"></i>&nbsp;Crear asignación</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-12 mt-2">
            <div class="card" style="width: 100%;">
                <div style="background-color: rgb(197, 197, 197);">
                </div>
                <div class="card-body">
                    <div class="row mt-3">
                        <div class="col-sm-12 tableFixHead">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">Compañía</th>
                                        <th scope="col">Alerta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let compAlert of companyAlertList">
                                        <td>{{ compAlert.CompanyName }}</td>
                                        <td>{{ compAlert.AlertName }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
